import request, { requestMethods } from '../../../lib/request.js';

// 新增单个客服
export const addService = async data => {
  const url = '/api/operation/services';

  return await request({
    url,
    method: requestMethods.POST,
    data,
  });
};

// 获取客服列表
export const getServices = async data => {
  const url = '/api/operation/services';

  return await request({
    url,
    data,
  });
};

// 获取单个客服
export const getService = async id => {
  const url = `/api/operation/services/${id}`;

  return await request({
    url,
  });
};

// 更新单个客服
export const updateService = async data => {
  const { id } = data;
  const url = `/api/operation/services/${id}`;

  return await request({
    url,
    method: requestMethods.PUT,
    data,
  });
};

// 删除单个客服
export const removeService = async id => {
  const url = `/api/operation/services/${id}`;

  return await request({
    url,
    method: requestMethods.DELETE,
  });
};

// 更新单个客服的状态
export const updateServiceStatus = async data => {
  const url = '/api/operation/services/update/status';

  return await request({
    url,
    method: requestMethods.PUT,
    data,
  });
};
