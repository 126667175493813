<!-- header-action -->
<template>
  <div class="header-action">
    <!-- 折叠按钮 -->
    <div class="action-collapse" @click="handleActionCollapseClick">
      <span
        class="action-icon el-icon-s-fold"
        :class="{ collapsed: isAsideCollapsed }"
      />
    </div>

    <!-- 刷新按钮 -->
    <div class="action-refresh" @click="handleActionRefreshClick">
      <span class="action-icon el-icon-refresh-right" />
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';

  export default {
    name: 'header-action',
    computed: {
      ...mapState(['isAsideCollapsed']),
    },
    methods: {
      ...mapMutations(['mttIsAsideCollapse', 'mttIsRouterViewActivated']),

      // 折叠按钮单击
      handleActionCollapseClick() {
        this.mttIsAsideCollapse(!this.isAsideCollapsed);
      },
      // 刷新按钮单击
      async handleActionRefreshClick() {
        this.mttIsRouterViewActivated(false);

        await this.$nextTick();

        this.mttIsRouterViewActivated(true);
      },
    },
  };
</script>

<style scoped lang="scss">
  .header-action {
    display: flex;
    justify-content: space-between;
    height: 100%;
    overflow: hidden;
    font-size: 18px;

    .action-collapse,
    .action-refresh {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 10px;
      cursor: pointer;

      &:hover {
        background-color: #f8f8f9;
      }
    }

    .action-collapse {
      overflow: hidden;

      .action-icon {
        transition: transform 0.3s ease-in-out;

        &.collapsed {
          transform: rotateZ(180deg);
        }
      }
    }
  }
</style>
