// 资源类型
export default {
  map: {
    1: '菜单',
    2: '按钮',
  },
  enum: {
    menu: 1,
    button: 2,
  },
};
