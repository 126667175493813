import {
  getGamesAndAnchorsByDateRange,
  getOverviewBenefit,
  getOverviewWork,
  getGamePercent,
  getGameRank,
  getGameBenefit,
  getAnchorBenefit,
  getAnchorPercent,
  getAnchorRank,
  getUserPercent,
  getUserRank,
  getUserStatistics,
} from '../../api/home';

const state = {
  // 获取统计数据的日期范围
  dateRange: [],
  // 统计数据的日期范围内排期了的或产生了流水的游戏列表和主播列表
  gamesAndAnchors: null,
  // 概览中选中的用户 id
  overviewUserId: '',
  // 概览中的效益统计
  overviewBenefit: [],
  // 概览中的工作统计
  overviewWork: [],
  // 游戏中的占比统计
  gamePercent: null,
  // 游戏中的排行统计
  gameRank: null,
  // 游戏中的效益统计
  gameBenefit: [],
  // 主播中的效益统计
  anchorBenefit: [],
  // 主播中的占比统计
  anchorPercent: null,
  // 主播中的排行统计
  anchorRank: [],
  // 商务中的占比统计
  userPercent: null,
  // 商务中的排行统计
  userRank: [],
  // 商务中的统计数据
  userStatistics: [],
};

const getters = {
  // 统计数据的日期范围内排期了的游戏列表
  games(state) {
    return state.gamesAndAnchors?.games || [];
  },
  // 统计数据的日期范围内排期了的主播列表
  anchors(state) {
    return state.gamesAndAnchors?.anchors || [];
  },
};

const mutations = {
  mttDateRange(state, payload) {
    state.dateRange = payload || [];
  },
  mttGamesAndAnchors(state, payload) {
    state.gamesAndAnchors = payload || null;
  },
  mttOverviewUserId(state, payload) {
    state.overviewUserId = payload || '';
  },
  mttOverviewBenefit(state, payload) {
    state.overviewBenefit = payload || [];
  },
  mttOverviewWork(state, payload) {
    state.overviewWork = payload || [];
  },
  mttGamePercent(state, payload) {
    state.gamePercent = payload || null;
  },
  mttGameRank(state, payload) {
    state.gameRank = payload || null;
  },
  mttGameBenefit(state, payload) {
    state.gameBenefit = payload || [];
  },
  mttAnchorBenefit(state, payload) {
    state.anchorBenefit = payload || [];
  },
  mttAnchorPercent(state, payload) {
    state.anchorPercent = payload || null;
  },
  mttAnchorRank(state, payload) {
    state.anchorRank = payload || [];
  },
  mttUserPercent(state, payload) {
    state.userPercent = payload || null;
  },
  mttUserRank(state, payload) {
    state.userRank = payload || [];
  },
  mttUserStatistics(state, payload) {
    state.userStatistics = payload || [];
  },
};

const actions = {
  // 获取指定时间范围内排期了的或产生了流水的游戏列表和主播列表
  async actGamesAndAnchors({ state, commit }) {
    const { dateRange: [startTime, endTime] = [] } = state;
    const params = {
      startTime,
      endTime,
    };
    const res = await getGamesAndAnchorsByDateRange(params);

    if (!res) return false;

    const { data = null } = res;

    commit('mttGamesAndAnchors', data);

    return true;
  },
  // 获取概览中的效益统计
  async actOverviewBenefit({ state, commit }) {
    const {
      dateRange: [startTime, endTime],
      overviewUserId,
    } = state;
    const params = {
      startTime,
      endTime,
      userIds: overviewUserId,
    };
    const res = await getOverviewBenefit(params);

    if (!res) return false;

    const { data = null } = res;

    commit('mttOverviewBenefit', data);

    return true;
  },
  // 获取概览中的工作统计
  async actOverviewWork({ state, commit }) {
    const {
      dateRange: [startTime, endTime],
      overviewUserId,
    } = state;
    const params = {
      startTime,
      endTime,
      userIds: overviewUserId,
    };
    const res = await getOverviewWork(params);

    if (!res) return false;

    const { data = null } = res;

    commit('mttOverviewWork', data);

    return true;
  },
  // 获取游戏中的占比统计
  async actGamePercent({ state, commit }) {
    const {
      dateRange: [startTime, endTime],
    } = state;
    const params = {
      startTime,
      endTime,
    };
    const res = await getGamePercent(params);

    if (!res) return false;

    const { data = null } = res;

    commit('mttGamePercent', data);

    return true;
  },
  // 获取游戏中的排行统计
  async actGameRank({ state, commit }, payload) {
    const {
      dateRange: [startTime, endTime],
    } = state;
    const params = {
      startTime,
      endTime,
      ...payload,
    };
    const res = await getGameRank(params);

    if (!res) return false;

    const { data = null } = res;

    commit('mttGameRank', data);

    return true;
  },
  // 获取游戏中的效益统计
  async actGameBenefit({ state, commit }, payload) {
    const {
      dateRange: [startTime, endTime],
    } = state;
    const params = {
      startTime,
      endTime,
      ...payload,
    };
    const res = await getGameBenefit(params);

    if (!res) return false;

    const { data = null } = res;

    commit('mttGameBenefit', data);

    return true;
  },
  // 获取主播中的效益统计
  async actAnchorBenefit({ state, commit }, payload) {
    const {
      dateRange: [startTime, endTime],
    } = state;
    const params = {
      startTime,
      endTime,
      ...payload,
    };
    const res = await getAnchorBenefit(params);

    if (!res) return false;

    const { data = null } = res;

    commit('mttAnchorBenefit', data);

    return true;
  },
  // 获取主播中的占比统计
  async actAnchorPercent({ state, commit }) {
    const {
      dateRange: [startTime, endTime],
    } = state;
    const params = {
      startTime,
      endTime,
    };
    const res = await getAnchorPercent(params);

    if (!res) return false;

    const { data = null } = res;

    commit('mttAnchorPercent', data);

    return true;
  },
  // 获取主播中的排行统计
  async actAnchorRank({ state, commit }) {
    const {
      dateRange: [startTime, endTime],
    } = state;
    const params = {
      startTime,
      endTime,
    };
    const res = await getAnchorRank(params);

    if (!res) return false;

    const { data = [] } = res;

    commit('mttAnchorRank', data);

    return true;
  },
  // 获取商务中的占比统计
  async actUserPercent({ state, commit }) {
    const {
      dateRange: [startTime, endTime],
    } = state;
    const params = {
      startTime,
      endTime,
    };
    const res = await getUserPercent(params);

    if (!res) return false;

    const { data = null } = res;

    commit('mttUserPercent', data);

    return true;
  },
  // 获取商务中的排行统计
  async actUserRank({ state, commit }, payload) {
    const {
      dateRange: [startTime, endTime],
    } = state;
    const params = {
      startTime,
      endTime,
      ...payload,
    };
    const res = await getUserRank(params);

    if (!res) return false;

    const { data = [] } = res;

    commit('mttUserRank', data);

    return true;
  },
  // 获取商务中的统计数据
  async actUserStatistics({ state, commit }) {
    const {
      dateRange: [startTime, endTime],
    } = state;
    const params = {
      startTime,
      endTime,
    };
    const res = await getUserStatistics(params);

    if (!res) return false;

    const { data = [] } = res;

    commit('mttUserStatistics', data);

    return true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
