// 权限管理
export default [
  {
    name: 'auth-user',
    path: '/auth-user',
    component: () =>
      import(/* webpackChunkName: 'auth-user' */ '../../pages/auth/auth-user'),
  },
  {
    name: 'auth-role',
    path: '/auth-role',
    component: () =>
      import(/* webpackChunkName: 'auth-role' */ '../../pages/auth/auth-role'),
  },
];
