import request, { requestMethods } from '../../../lib/request.js';

// 新增单个流水
export const addSerial = async data => {
  const url = '/api/anchor/serials';

  return await request({
    url,
    method: requestMethods.POST,
    data,
  });
};

// 获取流水列表
export const getSerials = async data => {
  const url = '/api/anchor/serials';

  return await request({
    url,
    data,
  });
};

// 获取单个流水
export const getSerial = async id => {
  const url = `/api/anchor/serials/${id}`;

  return await request({
    url,
  });
};

// 更新单个流水
export const updateSerial = async data => {
  const { id } = data;
  const url = `/api/anchor/serials/${id}`;

  return await request({
    url,
    method: requestMethods.PUT,
    data,
  });
};

// 删除单个/多个流水
export const removeSerials = async ids => {
  const url = `/api/anchor/serials/${ids.join()}`;

  return await request({
    url,
    method: requestMethods.DELETE,
  });
};
