import { menuHistoryKey, isMenuHistoryPermanent } from '../config/menu-history';

const storage = isMenuHistoryPermanent
  ? window.localStorage
  : window.sessionStorage;

// 获取 menu-history
export const getMenuHistory = () => {
  const menuHistoryStr = storage.getItem(menuHistoryKey) || '';

  try {
    return JSON.parse(menuHistoryStr);
  } catch {
    return [];
  }
};

// 设置 menu-history
export const setMenuHistory = menuHistory => {
  storage.setItem(menuHistoryKey, JSON.stringify(menuHistory || []));
};

// 删除 menu-history
export const removeMenuHistory = () => storage.removeItem(menuHistoryKey);
