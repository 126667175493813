// 资源可见性
export default {
  map: {
    1: '可见',
    2: '不可见',
  },
  enum: {
    visible: 1,
    invisible: 2,
  },
};
