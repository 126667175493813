// 运营管理
export default [
  {
    name: 'operation-promotion',
    path: '/operation-promotion',
    component: () =>
      import(
        /* webpackChunkName: 'operation-promotion' */ '../../pages/operation/operation-promotion'
      ),
  },
  {
    name: 'operation-service',
    path: '/operation-service',
    component: () =>
      import(
        /* webpackChunkName: 'operation-service' */ '../../pages/operation/operation-service'
      ),
  },
];
