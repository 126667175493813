import request from '../../lib/request.js';

// 获取组织架构
export const getOrg = async () => {
  const url = '/api/common/org';

  return await request({ url });
};

// 获取用户信息
export const getUserInfo = async () => {
  const url = '/api/common/user';

  return await request({ url });
};

// 获取主播列表，需要判断角色数据权限
export const getAnchors = async data => {
  const url = '/api/common/anchors';

  return await request({
    url,
    data,
  });
};

// 获取签约列表(用于签约对话框)，需要判断角色数据权限
export const getSigns = async data => {
  const url = '/api/common/signs';

  return await request({
    url,
    data,
  });
};

// 获取签约列表(用于签约下拉框)，需要判断角色数据权限
export const getSignsSelect = async data => {
  const url = '/api/common/signs-select';

  return await request({
    url,
    data,
  });
};

// 获取用户列表
export const getUsers = async data => {
  const url = '/api/common/users';

  return await request({
    url,
    data,
  });
};

// 指定日期范围内(包含)有合作的主播，即: 这段日期范围内至少经历过签约的主播
export const getAnchorsByDateRange = async data => {
  const url = '/api/common/anchors/by-date-range';

  return await request({
    url,
    data,
  });
};
