import { getSchedules } from '../../../api/anchor/schedule';

const state = {
  // 排期列表
  schedules: [],
  // 排期总数
  total: 0,
  // 当前操作的排期签约 id
  scheduleSignId: '',
};

const getters = {
  // 当前操作的排期对象
  scheduleObj(state) {
    const { schedules, scheduleSignId } = state;

    return schedules.find(item => item._id === scheduleSignId) || null;
  },
  // 当前操作的排期对象所关联的主播名称
  scheduleAnchorName(state, getters) {
    return getters.scheduleObj?.anchorName || '';
  },
};

const mutations = {
  mttSchedules(state, payload) {
    state.schedules = payload || [];
  },
  mttTotal(state, payload) {
    state.total = payload || 0;
  },
  mttScheduleSignId(state, payload) {
    state.scheduleSignId = payload || '';
  },
};

const actions = {
  // 获取排期列表
  async actSchedules({ commit }, payload) {
    const res = await getSchedules(payload);

    if (!res) return false;

    const { data: { total = 0, list = [] } = {} } = res;

    commit('mttSchedules', list);
    commit('mttTotal', total);

    return true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
