import request, { requestMethods } from '../../../lib/request.js';

// 新增单个角色
export const addRole = async data => {
  const url = '/api/auth/roles';

  return await request({
    url,
    method: requestMethods.POST,
    data,
  });
};

// 获取角色列表
export const getRoles = async data => {
  const url = '/api/auth/roles';

  return await request({
    url,
    data,
  });
};

// 获取单个角色
export const getRole = async id => {
  const url = `/api/auth/roles/${id}`;

  return await request({
    url,
  });
};

// 更新单个角色
export const updateRole = async data => {
  const { id } = data;
  const url = `/api/auth/roles/${id}`;

  return await request({
    url,
    method: requestMethods.PUT,
    data,
  });
};

// 删除单个/多个角色
export const removeRoles = async ids => {
  const url = `/api/auth/roles/${ids.join()}`;

  return await request({
    url,
    method: requestMethods.DELETE,
  });
};
