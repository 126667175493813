import follow from './follow';
import sign from './sign';
import schedule from './schedule';
import broadcast from './broadcast';
import serial from './serial';
import cooperation from './cooperation';

export default {
  namespaced: true,
  modules: {
    follow,
    sign,
    schedule,
    broadcast,
    serial,
    cooperation,
  },
};
