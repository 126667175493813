import store from '../store';

// 获取指定菜单的层级列表，即: 从根菜单到自身
const getMenuHierarchy = (
  menu,
  menuList = store.getters.menuList,
  ancestors = []
) => {
  if (!Array.isArray(menuList) || !menuList.length) return ancestors;

  ancestors.unshift(menu);

  const { pid } = menu;

  if (!pid) return ancestors;

  const pMenu = menuList.find(item => item._id === pid);

  return getMenuHierarchy(pMenu, menuList, ancestors);
};

export default getMenuHierarchy;
