import request, { requestMethods } from '../../../lib/request.js';

// 新增单个字典
export const addDictionary = async data => {
  const url = '/api/sys/dictionaries';

  return await request({
    url,
    method: requestMethods.POST,
    data,
  });
};

// 获取字典列表
export const getDictionaries = async data => {
  const url = '/api/sys/dictionaries';

  return await request({
    url,
    data,
  });
};

// 获取单个字典
export const getDictionary = async id => {
  const url = `/api/sys/dictionaries/${id}`;

  return await request({
    url,
  });
};

// 更新单个字典
export const updateDictionary = async data => {
  const { id } = data;
  const url = `/api/sys/dictionaries/${id}`;

  return await request({
    url,
    method: requestMethods.PUT,
    data,
  });
};

// 删除单个/多个字典
export const removeDictionaries = async ids => {
  const url = `/api/sys/dictionaries/${ids.join()}`;

  return await request({
    url,
    method: requestMethods.DELETE,
  });
};
