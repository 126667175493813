<!-- layout-aside -->
<template>
  <el-scrollbar class="layout-aside">
    <el-menu
      class="aside-menu"
      :default-active="currActiveRouteName"
      :collapse="isAsideCollapsed"
      @select="handleMenuItemSelect"
    >
      <template v-for="menu in menuTree">
        <!-- 没有子菜单 -->
        <el-menu-item
          v-if="!menu.children || !menu.children.length"
          :index="menu.value"
          :key="menu._id"
        >
          <i class="menu-icon iconfont" :class="[`icon-${menu.value}`]" />
          <template v-slot:title>
            <span>{{ menu.name }}</span>
          </template>
        </el-menu-item>

        <!-- 有子菜单 -->
        <el-submenu v-else :index="menu.value" :key="menu._id">
          <template v-slot:title>
            <i class="menu-icon iconfont" :class="[`icon-${menu.value}`]" />
            <span>{{ menu.name }}</span>
          </template>

          <el-menu-item
            v-for="submenu in menu.children"
            :index="submenu.value"
            :key="submenu._id"
          >
            <i class="menu-icon iconfont" :class="[`icon-${submenu.value}`]" />
            <span>{{ submenu.name }}</span>
          </el-menu-item>
        </el-submenu>
      </template>
    </el-menu>
  </el-scrollbar>
</template>

<script>
  import { mapState, mapGetters, mapActions } from 'vuex';
  import {
    isStrictlyFollowMenuTree,
    menuHistoryLimit,
  } from '../../../config/menu-history';
  import resourceVisible from '../../../enum/resource-visible';

  export default {
    name: 'layout-aside',
    computed: {
      ...mapState(['isAsideCollapsed', 'menuHistory']),
      ...mapGetters(['menuList', 'menuTree', 'userHomeRouteName']),

      // 当前路由名称
      currRouteName() {
        const { name = '' } = this.$route;

        return name || '';
      },
      // 激活的菜单
      currActiveMenu() {
        return this.menuList.find(({ value }) => value === this.currRouteName);
      },
      // 激活菜单的路由名称
      currActiveRouteName() {
        const { meta: { highlightMenuRouteName = '' } = {} } = this.$route;

        // 如果为当前路由配置了高亮菜单的路由名称，则返回它
        if (highlightMenuRouteName) return highlightMenuRouteName;

        // 否则，返回当前激活菜单的路由名称
        const { value = '' } = this.currActiveMenu || {};

        return value || '';
      },
    },
    methods: {
      ...mapActions(['actMenuHistory']),

      // 添加到菜单历史记录
      addToMenuHistory() {
        const menu = this.currActiveMenu;

        if (!menu) return;

        const clonedMenuHistory = [...this.menuHistory];
        const { visible, value } = menu;

        if (
          isStrictlyFollowMenuTree &&
          visible === resourceVisible.enum.invisible
        )
          return;

        const isExisted = clonedMenuHistory.some(item => item.value === value);

        // 已存在
        if (isExisted) return;

        const menuHistoryLength = clonedMenuHistory.length;

        // 超出限制时，移除第一个非用户主页对应的菜单项
        if (menuHistoryLength >= menuHistoryLimit) {
          const firstNonUserHomeMenuIndex = clonedMenuHistory.findIndex(
            item => item.value !== this.userHomeRouteName
          );

          clonedMenuHistory.splice(firstNonUserHomeMenuIndex, 1);
        }

        clonedMenuHistory.push(menu);

        this.actMenuHistory(clonedMenuHistory);
      },
      // 菜单项选中
      handleMenuItemSelect(menuRouteName) {
        if (menuRouteName === this.currRouteName) return;

        this.$router.push({ name: menuRouteName });
      },
    },
    watch: {
      $route: {
        immediate: true,
        handler() {
          this.addToMenuHistory();
        },
      },
    },
  };
</script>

<style scoped lang="scss">
  .layout-aside {
    flex-grow: 1;

    /deep/ .el-scrollbar__wrap {
      height: 100%;
      overflow: hidden auto;
    }

    /deep/ .aside-menu {
      width: 100%;
      height: 100%;
      border: none;

      .el-menu-item {
        display: flex;
        align-items: center;
        height: 50px;
        line-height: 50px;
        color: #666;

        &.is-active {
          color: #2d8cf0;
        }
      }

      .el-submenu {
        .el-submenu__title {
          display: flex;
          align-items: center;
          height: 50px;
          line-height: 50px;
          color: #666;
        }

        .el-submenu__icon-arrow {
          margin-left: 5px;
        }
      }

      &.el-menu--collapse {
        .el-submenu.is-active .el-submenu__title .menu-icon {
          color: #2d8cf0;
        }
      }
    }
  }

  .menu-icon {
    margin-right: 8px;

    // 主播管理
    &.icon-anchor {
      font-size: 17px;
    }

    // 主播流水
    &.icon-anchor-serial {
      margin-left: -2px;
      font-size: 15px;
    }
  }
</style>
