import { getDictionaries } from '../../../api/sys/dictionary';

const state = {
  // 字典列表
  dictionaries: [],
  // 字典总数
  total: 0,
};

const mutations = {
  mttDictionaries(state, payload) {
    state.dictionaries = payload || [];
  },
  mttTotal(state, payload) {
    state.total = payload || 0;
  },
};

const actions = {
  // 获取字典列表
  async actDictionaries({ commit }, payload) {
    const res = await getDictionaries(payload);

    if (!res) return false;

    const { data: { total = 0, list = [] } = {} } = res;

    commit('mttDictionaries', list);
    commit('mttTotal', total);

    return true;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
