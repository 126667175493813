import {
  getBroadcasts,
  getBroadcastRemarks,
} from '../../../api/anchor/broadcast';

const state = {
  // 开播记录列表
  broadcasts: [],
  // 开播记录总数
  total: 0,
  // 当前操作的开播记录 id
  broadcastId: '',
  // 开播记录备注列表
  broadcastRemarks: [],
  // 当前操作的开播记录备注 id
  broadcastRemarkId: '',
};

const getters = {
  // 当前操作的开播记录对象
  broadcastObj(state) {
    const { broadcasts, broadcastId } = state;

    return broadcasts.find(item => item._id === broadcastId) || null;
  },
  // 当前操作的开播记录对象所关联的主播名称
  broadcastAnchorName(state, getters) {
    return getters.broadcastObj?.anchorName || '';
  },
};

const mutations = {
  mttBroadcasts(state, payload) {
    state.broadcasts = payload || [];
  },
  mttTotal(state, payload) {
    state.total = payload || 0;
  },
  mttBroadcastId(state, payload) {
    state.broadcastId = payload || '';
  },
  mttBroadcastRemarks(state, payload) {
    state.broadcastRemarks = payload || [];
  },
  mttBroadcastRemarkId(state, payload) {
    state.broadcastRemarkId = payload || '';
  },
};

const actions = {
  // 获取开播记录列表
  async actBroadcasts({ commit }, payload) {
    const res = await getBroadcasts(payload);

    if (!res) return false;

    const { data: { total = 0, list = [] } = {} } = res;

    commit('mttBroadcasts', list);
    commit('mttTotal', total);

    return true;
  },
  // 获取开播记录备注列表
  async actBroadcastRemarks({ state, commit }) {
    const params = { broadcastId: state.broadcastId };
    const res = await getBroadcastRemarks(params);

    if (!res) return false;

    const { data = [] } = res;

    commit('mttBroadcastRemarks', data);

    return true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
