// 根据菜单树，获取第一个叶子菜单
const getFirstLeafMenu = menuTree => {
  if (!Array.isArray(menuTree) || !menuTree.length) return null;

  const firstMenu = menuTree[0];

  if (!firstMenu) return null;

  const { children } = firstMenu;

  if (!Array.isArray(children) || !children.length) return firstMenu;

  return getFirstLeafMenu(children);
};

export default getFirstLeafMenu;
