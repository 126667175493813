// 响应的业务码
export default {
  // 成功
  SUCCESS: 200,
  // 请求参数有误
  PARAMS_ERROR: 400,
  // 无效的Token
  TOKEN_INVALID: 401,
  // Token已过期
  TOKEN_EXPIRES: 403,
  // 资源不存在
  NOT_FOUND: 404,
  // 服务内部错误
  INTERNAL_ERROR: 500,
};
