import { getResources } from '../../../api/sys/resource';

const state = {
  // 资源列表
  resources: [],
  // 资源总数
  total: 0,
};

const mutations = {
  mttResources(state, payload) {
    state.resources = payload || [];
  },
  mttTotal(state, payload) {
    state.total = payload || 0;
  },
};

const actions = {
  // 获取资源列表
  async actResources({ commit }, payload) {
    const res = await getResources(payload);

    if (!res) return false;

    const { data: { total = 0, list = [] } = {} } = res;

    commit('mttResources', list);
    commit('mttTotal', total);

    return true;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
