import router from '../router';
import { removeToken } from './token';
import { loginKey, loginExpires } from '../config/login';
import { showSystemNotice, systemNoticeType } from './system-notice';

// 重定向到登录页
export const redirectLogin = async (
  redirectPath = '',
  ignoreRedirectPath = false
) => {
  try {
    // 重定向前删除 token
    removeToken();

    // 当前路由全路径
    const { history: { current: { fullPath } } = {} } = router;
    // 登录后重定向到的全路径
    const redirectFullPath = redirectPath || fullPath;

    await router.replace({
      name: 'login',
      query: {
        redirect: ignoreRedirectPath
          ? undefined
          : encodeURIComponent(redirectFullPath),
      },
    });

    return true;
  } catch (e) {
    return false;
  }
};

const storage = window.localStorage;

// 获取缓存的登录数据
export const getCachedLoginData = () => {
  try {
    return JSON.parse(storage.getItem(loginKey));
  } catch (e) {
    return null;
  }
};

// 设置缓存的登录数据
export const setCachedLoginData = data => {
  if (!data) return;

  // 记录登录数据缓存的超时时间
  data.expires = Date.now() + loginExpires;

  storage.setItem(loginKey, JSON.stringify(data));
};

// 删除缓存的登录数据
export const removeCachedLoginData = () => storage.removeItem(loginKey);

// 显示未登录的系统通知
export const showLoginSystemNotice = () => {
  showSystemNotice({
    title: '未登录',
    type: systemNoticeType.INFO,
    message: '尚未登录，请先登录',
  });
};
