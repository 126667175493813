// 主播管理
export default [
  {
    name: 'anchor-follow',
    path: '/anchor-follow',
    component: () =>
      import(
        /* webpackChunkName: 'anchor-follow' */ '../../pages/anchor/anchor-follow'
      ),
  },
  {
    name: 'anchor-sign',
    path: '/anchor-sign',
    component: () =>
      import(
        /* webpackChunkName: 'anchor-sign' */ '../../pages/anchor/anchor-sign'
      ),
  },
  {
    name: 'anchor-schedule',
    path: '/anchor-schedule',
    component: () =>
      import(
        /* webpackChunkName: 'anchor-schedule' */ '../../pages/anchor/anchor-schedule'
      ),
  },
  {
    name: 'anchor-broadcast',
    path: '/anchor-broadcast',
    component: () =>
      import(
        /* webpackChunkName: 'anchor-broadcast' */ '../../pages/anchor/anchor-broadcast'
      ),
  },
  {
    name: 'anchor-serial',
    path: '/anchor-serial',
    component: () =>
      import(
        /* webpackChunkName: 'anchor-serial' */ '../../pages/anchor/anchor-serial'
      ),
  },
  {
    name: 'anchor-cooperation',
    path: '/anchor-cooperation',
    component: () =>
      import(
        /* webpackChunkName: 'anchor-cooperation' */ '../../pages/anchor/anchor-cooperation'
      ),
  },
  {
    name: 'anchor-cooperation-view',
    props: true,
    path: '/anchor-cooperation-view/:id',
    component: () =>
      import(
        /* webpackChunkName: 'anchor-cooperation-view' */ '../../pages/anchor/anchor-cooperation/cooperation-view'
      ),
    meta: {
      highlightMenuRouteName: 'anchor-cooperation',
    },
  },
];
