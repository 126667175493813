import { tokenKey, isTokenPermanent } from '../config/token';
import { showSystemNotice, systemNoticeType } from './system-notice';

const storage = isTokenPermanent ? window.localStorage : window.sessionStorage;

// 获取 token
export const getToken = () => storage.getItem(tokenKey) || '';

// 设置 token
export const setToken = token => storage.setItem(tokenKey, token);

// 删除 token
export const removeToken = () => storage.removeItem(tokenKey);

// 是否已显示 token 失效的系统通知
let hadShownTokenInvalid = false;

// 显示 token 失效的系统通知
export const showTokenInvalidSystemNotice = (
  options = {
    title: '登录失效',
    type: systemNoticeType.ERROR,
    message: '登录已失效，请重新登录',
  }
) => {
  // 如果已经显示过 token 失效的系统通知，则不再显示
  if (hadShownTokenInvalid) return;

  hadShownTokenInvalid = true;
  showSystemNotice(options);
};

// 清空 token 失效的系统通知，其实是重置 hadShownTokenInvalid 的值
export const clearTokenInvalidSystemNotice = () => {
  hadShownTokenInvalid && (hadShownTokenInvalid = false);
};
