<!-- layout-logo -->
<template>
  <div class="layout-logo" @click="handleLogoClick">
    <img class="logo-img" :style="logoStyle" :src="logoSrc" />
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import logo from '../../../assets/img/logo.png';
  import logoIcon from '../../../assets/img/logo-icon.png';

  export default {
    name: 'layout-logo',
    computed: {
      ...mapState(['isAsideCollapsed']),
      ...mapGetters(['userHomeRouteName']),

      logoStyle() {
        return {
          maxHeight: `${this.isAsideCollapsed ? 30 : 54}px`,
        };
      },
      logoSrc() {
        return this.isAsideCollapsed ? logoIcon : logo;
      },
    },
    methods: {
      handleLogoClick() {
        this.$router.push({ name: this.userHomeRouteName });
      },
    },
  };
</script>

<style scoped lang="scss">
  .layout-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;

    .logo-img {
      max-width: 100%;
      max-height: 44px;
    }
  }
</style>
