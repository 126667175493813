import {
  getCooperation,
  getCooperationBroadcasts,
  getCooperationBroadcastsRemarks,
  getCooperationSerials,
} from '../../../../api/anchor/cooperation';
import { getSign, getSignRemarks } from '../../../../api/anchor/sign';
import { getSchedule } from '../../../../api/anchor/schedule';

const state = {
  // 合作 id
  cooperationId: '',
  // 合作对象
  cooperation: null,
  // 签约对象
  cooperationSign: null,
  // 签约备注列表
  cooperationSignRemarks: [],
  // 排期对象
  cooperationSchedule: null,
  // 开播记录列表
  cooperationBroadcasts: [],
  // 开播记录备注列表
  cooperationBroadcastsRemarks: [],
  // 主播流水列表
  cooperationSerials: [],
};

const getters = {
  // 合作关联的签约 id
  signId(state) {
    return state.cooperation?.signId || '';
  },
  // 合作关联的开播记录 id 列表
  broadcastIds(state) {
    return state.cooperationBroadcasts?.map(item => item._id) || [];
  },
};

const mutations = {
  mttCooperationId(state, payload) {
    state.cooperationId = payload || '';
  },
  mttCooperation(state, payload) {
    state.cooperation = payload || null;
  },
  mttCooperationSign(state, payload) {
    state.cooperationSign = payload || null;
  },
  mttCooperationSignRemarks(state, payload) {
    state.cooperationSignRemarks = payload || [];
  },
  mttCooperationSchedule(state, payload) {
    state.cooperationSchedule = payload || null;
  },
  mttCooperationBroadcasts(state, payload) {
    state.cooperationBroadcasts = payload || [];
  },
  mttCooperationBroadcastsRemarks(state, payload) {
    state.cooperationBroadcastsRemarks = payload || [];
  },
  mttCooperationSerials(state, payload) {
    state.cooperationSerials = payload || [];
  },
};

const actions = {
  // 获取合作对象
  async actCooperation({ state, commit }) {
    const { cooperationId } = state;
    const res = await getCooperation(cooperationId);

    if (!res) return false;

    commit('mttCooperation', res.data);

    return true;
  },
  // 获取签约对象
  async actCooperationSign({ getters, commit }) {
    const { signId } = getters;

    if (!signId) return false;

    const res = await getSign(signId);

    if (!res) return false;

    commit('mttCooperationSign', res.data);

    return true;
  },
  // 获取签约备注列表
  async actCooperationSignRemarks({ getters, commit }) {
    const { signId } = getters;

    if (!signId) return false;

    const params = { signId };
    const res = await getSignRemarks(params);

    if (!res) return false;

    commit('mttCooperationSignRemarks', res.data);

    return true;
  },
  // 获取排期对象
  async actCooperationSchedule({ getters, commit }) {
    const { signId } = getters;

    if (!signId) return false;

    const res = await getSchedule(signId);

    if (!res) return false;

    commit('mttCooperationSchedule', res.data);

    return true;
  },
  // 获取开播记录列表
  async actCooperationBroadcasts({ state, getters, commit }) {
    const { cooperationId } = state;
    const { signId } = getters;

    if (!cooperationId || !signId) return false;

    const params = {
      id: cooperationId,
      signId,
    };
    const res = await getCooperationBroadcasts(params);

    if (!res) return false;

    commit('mttCooperationBroadcasts', res.data);

    return true;
  },
  // 获取开播记录备注列表
  async actCooperationBroadcastsRemarks({ state, getters, commit }) {
    const { cooperationId } = state;
    const { broadcastIds } = getters;

    if (!broadcastIds.length) return false;

    const params = {
      id: cooperationId,
      broadcastIds: broadcastIds.join(),
    };
    const res = await getCooperationBroadcastsRemarks(params);

    if (!res) return false;

    commit('mttCooperationBroadcastsRemarks', res.data);

    return true;
  },
  // 获取主播流水列表
  async actCooperationSerials({ state, getters, commit }) {
    const { cooperationId } = state;
    const { signId } = getters;

    if (!cooperationId || !signId) return false;

    const params = {
      id: cooperationId,
      signId,
    };
    const res = await getCooperationSerials(params);

    if (!res) return false;

    commit('mttCooperationSerials', res.data);

    return true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
