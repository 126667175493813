import Vue from 'vue';
import { clearSystemNotice, showSystemNotice } from '../lib/system-notice';

// 判断元素是否已禁用
export const isDisabled = el => !!el.getAttribute('disabled');

// 禁用元素
export const disableEl = el => {
  el.classList.add('is-disabled');
  el.setAttribute('disabled', 'disabled');
};

// 为指令所在的元素注入遮罩层子元素，用于绑定事件
export const injectModalChildEl = el => {
  const elPosition = el.style.position;
  (!elPosition || elPosition === 'static') && (el.style.position = 'relative');

  const triggerEl = document.createElement('div');
  triggerEl.style.display = 'block';
  triggerEl.style.position = 'absolute';
  triggerEl.style.top = '0';
  triggerEl.style.right = '0';
  triggerEl.style.bottom = '0';
  triggerEl.style.left = '0';
  triggerEl.style.backgroundColor = 'transparent';
  triggerEl.style.cursor = 'not-allowed';

  el.appendChild(triggerEl);

  return triggerEl;
};

// 根据传入的布尔值判断是否禁用
Vue.directive('disable', {
  bind(el, binding) {
    const {
      value,
      // 用作通知消息的文案
      arg = '暂无权限，禁止操作',
      // 如果传入了 silence 修饰符，则不显示通知消息
      modifiers: { silence = false },
    } = binding;

    if (!value || isDisabled(el)) return;

    disableEl(el);

    if (silence) return;

    injectModalChildEl(el).addEventListener(
      'click',
      e => {
        e.stopPropagation();

        clearSystemNotice();
        showSystemNotice({
          type: 'warning',
          title: '操作无效',
          message: arg,
        });
      },
      false
    );
  },
});
