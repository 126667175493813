// 合作历史详情
import view from './view';
import { getCooperations } from '../../../api/anchor/cooperation';

const state = {
  // 合作历史列表
  cooperations: [],
  // 合作历史总数
  total: 0,
  // 当前操作的合作历史 id
  cooperationId: '',
};

const mutations = {
  mttCooperations(state, payload) {
    state.cooperations = payload || [];
  },
  mttTotal(state, payload) {
    state.total = payload || 0;
  },
  mttCooperationId(state, payload) {
    state.cooperationId = payload || '';
  },
};

const actions = {
  // 获取合作历史列表
  async actCooperations({ commit }, payload) {
    const res = await getCooperations(payload);

    if (!res) return false;

    const { data: { total = 0, list = [] } = {} } = res;

    commit('mttCooperations', list);
    commit('mttTotal', total);

    return true;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  modules: {
    view,
  },
};
