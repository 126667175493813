// menu-history 缓存在本地的 key
export const menuHistoryKey = 'menuHistory';

// menuHistoryKey 保存的最大历史记录数
export const menuHistoryLimit = 15;

// 是否严格遵循菜单树中的菜单项来显示历史记录——菜单树中包含的才显示，不包含的则不显示
export const isStrictlyFollowMenuTree = true;

// 是否持久缓存，如果持久缓存，则缓存在 localStorage，否则缓存在 sessionStorage
export const isMenuHistoryPermanent = true;
