import request, { requestMethods } from '../../../lib/request.js';

// 新增单个排期
export const addSchedule = async data => {
  const url = '/api/anchor/schedules';

  return await request({
    url,
    method: requestMethods.POST,
    data,
  });
};

// 获取排期列表
export const getSchedules = async data => {
  const url = '/api/anchor/schedules';

  return await request({
    url,
    data,
  });
};

// 获取单个排期
export const getSchedule = async signId => {
  const url = `/api/anchor/schedules/${signId}`;

  return await request({
    url,
  });
};

// 更新单个排期
export const updateSchedule = async data => {
  const { signId } = data;
  const url = `/api/anchor/schedules/${signId}`;

  return await request({
    url,
    method: requestMethods.PUT,
    data,
  });
};

// 删除单个/多个排期
export const removeSchedules = async signIds => {
  const url = `/api/anchor/schedules/${signIds.join()}`;

  return await request({
    url,
    method: requestMethods.DELETE,
  });
};

// 投放单个/多个排期
export const publishSchedules = async data => {
  const url = '/api/anchor/schedules/signs/publish';

  return await request({
    url,
    method: requestMethods.PUT,
    data,
  });
};

// 结束单个/多个排期
export const finishSchedules = async data => {
  const url = '/api/anchor/schedules/signs/finish';

  return await request({
    url,
    method: requestMethods.PUT,
    data,
  });
};
