import Vue from 'vue';
import store from '../store';
import { isDisabled, disableEl, injectModalChildEl } from './v-disable';
import { clearSystemNotice, showSystemNotice } from '../lib/system-notice';

// 根据权限数据判断是否禁用-鉴权
Vue.directive('auth', {
  bind(el, binding) {
    const buttonList = store.getters.buttonList;
    const {
      value,
      // 用作通知消息的文案
      arg = '暂无权限，禁止操作',
      // 如果传入了 silence 修饰符，则不显示通知消息
      modifiers: { silence = false },
    } = binding;

    const { disable = true } =
      buttonList.find(item => item.url === value) || {};

    if (!disable || isDisabled(el)) return;

    disableEl(el);

    if (silence) return;

    injectModalChildEl(el).addEventListener(
      'click',
      e => {
        e.stopPropagation();

        clearSystemNotice();
        showSystemNotice({
          type: 'warning',
          title: '操作无效',
          message: arg,
        });
      },
      false
    );
  },
});
