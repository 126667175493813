import request, { requestMethods } from '../../../lib/request.js';

// 新增单个分组
export const addGroup = async data => {
  const url = '/api/auth/groups';

  return await request({
    url,
    method: requestMethods.POST,
    data,
  });
};

// 获取分组列表
export const getGroups = async () => {
  const url = '/api/auth/groups';

  return await request({
    url,
  });
};

// 获取单个分组
export const getGroup = async id => {
  const url = `/api/auth/groups/${id}`;

  return await request({
    url,
  });
};

// 更新单个分组
export const updateGroup = async data => {
  const { id } = data;
  const url = `/api/auth/groups/${id}`;

  return await request({
    url,
    method: requestMethods.PUT,
    data,
  });
};

// 删除单个/多个分组
export const removeGroups = async ids => {
  const url = `/api/auth/groups/${ids.join()}`;

  return await request({
    url,
    method: requestMethods.DELETE,
  });
};

// 新增单个用户
export const addUser = async data => {
  const url = '/api/auth/users';

  return await request({
    url,
    method: requestMethods.POST,
    data,
  });
};

// 获取用户列表
export const getUsers = async data => {
  const url = '/api/auth/users';

  return await request({
    url,
    data,
  });
};

// 获取单个用户
export const getUser = async id => {
  const url = `/api/auth/users/${id}`;

  return await request({
    url,
  });
};

// 更新单个用户
export const updateUser = async data => {
  const { id } = data;
  const url = `/api/auth/users/${id}`;

  return await request({
    url,
    method: requestMethods.PUT,
    data,
  });
};

// 删除单个/多个用户
export const removeUsers = async ids => {
  const url = `/api/auth/users/${ids.join()}`;

  return await request({
    url,
    method: requestMethods.DELETE,
  });
};
