import {
  historyKey,
  isHistoryPermanent,
} from '../config/library-anchor-history';

const storage = isHistoryPermanent
  ? window.localStorage
  : window.sessionStorage;

// 获取主播库搜索历史
export const getHistory = () => {
  const historyStr = storage.getItem(historyKey) || '';

  try {
    return JSON.parse(historyStr);
  } catch {
    return [];
  }
};

// 设置主播库搜索历史
export const setHistory = historyArr => {
  storage.setItem(historyKey, JSON.stringify(historyArr || []));
};

// 清空主播库搜索历史
export const removeHistory = () => storage.removeItem(historyKey);
