<template>
  <div class="app">
    <!-- 缓存页面 -->
    <keep-alive>
      <template v-if="keepAlive">
        <!-- 不使用布局组件 -->
        <template v-if="withoutLayout">
          <transition name="fade">
            <router-view v-if="isRouterViewActivated" />
          </transition>
        </template>

        <!-- 使用布局组件 -->
        <layout v-else>
          <transition name="fade">
            <router-view v-if="isRouterViewActivated" />
          </transition>
        </layout>
      </template>
    </keep-alive>

    <!-- 不缓存页面 -->
    <template v-if="!keepAlive">
      <!-- 不使用布局组件 -->
      <template v-if="withoutLayout">
        <transition name="fade">
          <router-view v-if="isRouterViewActivated" />
        </transition>
      </template>

      <!-- 使用布局组件 -->
      <layout v-else>
        <transition name="fade">
          <router-view v-if="isRouterViewActivated" />
        </transition>
      </layout>
    </template>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import Layout from './components/layout';

  export default {
    name: 'app',
    components: {
      Layout,
    },
    computed: {
      ...mapState(['isRouterViewActivated']),

      // 是否缓存页面
      keepAlive() {
        const { meta: { keepAlive } = {} } = this.$route;

        return !!keepAlive;
      },
      // 是否不使用全局布局组件 layout
      withoutLayout() {
        const { matched = [], meta: { withoutLayout } = {} } = this.$route;

        return !matched.length || withoutLayout;
      },
    },
  };
</script>

<style scoped lang="scss">
  .app {
    .fade-enter {
      opacity: 0;
    }

    .fade-enter-active {
      transition: opacity 0.3s 0.3s;
    }

    .fade-leave {
      opacity: 1;
    }

    .fade-leave-active {
      opacity: 0;
      transition: opacity 0.3s;
    }
  }
</style>
