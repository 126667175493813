import dictionary from './dictionary';
import resource from './resource';

export default {
  namespaced: true,
  modules: {
    dictionary,
    resource,
  },
};
