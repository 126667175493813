import request from '../../../lib/request.js';

// 获取合作历史列表
export const getCooperations = async data => {
  const url = '/api/anchor/cooperations';

  return await request({
    url,
    data,
  });
};

// 获取单个合作历史
export const getCooperation = async id => {
  const url = `/api/anchor/cooperations/${id}`;

  return await request({
    url,
  });
};

// 获取单个合作历史关联的开播记录列表
export const getCooperationBroadcasts = async data => {
  const { id } = data;
  const url = `/api/anchor/cooperations/${id}/broadcasts`;

  return await request({
    url,
    data,
  });
};

// 获取单个合作历史关联的开播记录备注列表
export const getCooperationBroadcastsRemarks = async data => {
  const { id } = data;
  const url = `/api/anchor/cooperations/${id}/broadcasts-remarks`;

  return await request({
    url,
    data,
  });
};

// 获取单个合作历史关联的主播流水列表
export const getCooperationSerials = async data => {
  const { id } = data;
  const url = `/api/anchor/cooperations/${id}/serials`;

  return await request({
    url,
    data,
  });
};
