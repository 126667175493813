<!-- header-breadcrumb -->
<template>
  <div class="header-breadcrumb">
    <div
      class="breadcrumb-item"
      v-for="menu in currRouteMenuHierarchy"
      :key="menu._id"
    >
      {{ menu.name }}
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import getMenuHierarchy from '../../../../lib/get-menu-hierarchy';

  export default {
    name: 'header-breadcrumb',
    computed: {
      ...mapGetters(['menuList']),

      // 当前路由对应的菜单项
      currRouteMenu() {
        const { name = '' } = this.$route;
        const matched = this.menuList.find(item => item.value === name);

        return matched || {};
      },
      // 当前路由对应的菜单层级列表
      currRouteMenuHierarchy() {
        return getMenuHierarchy(this.currRouteMenu);
      },
    },
  };
</script>

<style scoped lang="scss">
  .header-breadcrumb {
    display: flex;

    .breadcrumb-item {
      padding-left: 10px;
      color: #aaa;

      &::after {
        content: '/';
        margin-left: 5px;
      }

      &:nth-last-of-type(1) {
        color: #666;

        &::after {
          content: none;
        }
      }
    }
  }
</style>
