// 是否 mac 系统
export const isMac = () => {
  const regExp = /macintosh|max os x/i;

  return regExp.test(navigator.userAgent);
};

// 是否 windows 系统
export const isWindows = () => {
  const regExp = /windows|win32/i;

  return regExp.test(navigator.userAgent);
};
