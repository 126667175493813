import { Notification } from 'element-ui';

// system notice type
export const systemNoticeType = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};

// 显示 notice
export const showSystemNotice = options => Notification(options);

// 清空 notice
export const clearSystemNotice = () => Notification.closeAll();
