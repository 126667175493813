import resourceTypesEnum from '../enum/resource-type';

/**
 * @desc 根据资源列表和权限生成扁平化的菜单列表(一维数组)
 * @param {array} resourceList 资源列表
 * @returns {array}
 */
export default resourceList => {
  return Array.isArray(resourceList) && resourceList.length
    ? resourceList.filter(item => item.type === resourceTypesEnum.enum.menu)
    : [];
};
