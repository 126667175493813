import axios from 'axios';
import requestConf from '../config/request-conf';
import { getToken } from './token';
import bizCode from '../config/biz-code';
import { redirectLogin } from './login';
import { showLoadingBar, hideLoadingBar } from './loading-bar';
import { showFlashMessage, flashMessageType } from './flash-message';
import {
  showTokenInvalidSystemNotice,
  clearTokenInvalidSystemNotice,
} from './token';

// 请求 method 映射，用于约束传入的 method
export const requestMethods = {
  GET: 'GET',
  HEAD: 'HEAD',
  DELETE: 'DELETE',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
};

// 校验是否是有效的 method
const validateMethod = method => Object.values(requestMethods).includes(method);

// 响应 response type 映射，用于约束传入的 responseType
export const responseTypes = {
  JSON: 'json',
  BLOB: 'blob',
};

// 校验是否是有效的 response type
const validateResponseType = responseType =>
  Object.values(responseTypes).includes(responseType);

// axios实例
const instance = axios.create(requestConf);

// 请求拦截器
instance.interceptors.request.use(
  function (config) {
    const { headers } = config;

    // 请求头附加 token
    headers.Authorization = getToken();

    return config;
  },
  function (e) {
    console.error('http config error:', e);

    return Promise.reject(e);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  function (res) {
    const {
      data,
      headers: { 'Content-Type': responseType },
      config: { responseType: requestType },
    } = res;

    // 响应类型是 'application/octet-stream' 字节流
    if (
      requestType === responseTypes.BLOB &&
      responseType.includes('octet-stream')
    ) {
      // 在响应对象上添加 'isOctetStream' 字段，用于标识响应内容是否是字节流
      res.isOctetStream = true;

      return Promise.resolve(res);
    }

    // 响应类型是 'application/json'
    return Promise.resolve(data);
  },
  function (e) {
    console.error('http response error:', e);

    return Promise.reject(e);
  }
);

// 用于发送 http 请求
const request = async params => {
  if (!params) throw new Error('请求参数是必填的');

  const {
    url,
    method = requestMethods.GET,
    data,
    headers,
    timeout = instance.defaults.timeout,
    // 期望的响应类型，默认 JSON
    responseType = responseTypes.JSON,
    // 是否在请求过程中显示 loading bar，默认显示
    showLoading = true,
    // 是否在请求出错时显示 flash message，默认显示
    showMessage = true,
    // 上传中回调函数，参数为上传事件对象
    onUploadProgress = null,
    // 下载中回调函数，参数为下载事件对象
    onDownloadProgress = null,
  } = params;

  if (!url) throw new Error('参数中的 url 是必填的');

  if (!validateMethod(method))
    throw new Error('参数中的 method 有误，请通过 request.methods 指定');

  if (!validateResponseType(responseType))
    throw new Error(
      '参数中的 responseType 有误，请通过 request.responseType 指定'
    );

  // 请求基本配置
  const reqBaseConf = {
    url,
    method,
    headers,
    timeout,
    responseType,
    onUploadProgress,
    onDownloadProgress,
  };

  // 请求参数配置
  const reqContentType = headers
    ? headers['Content-Type'] || headers['content-type']
    : '';
  const reqPayload =
    method === requestMethods.GET ||
    method === requestMethods.HEAD ||
    method === requestMethods.DELETE
      ? // get, head, delete
        {
          params: data,
        }
      : // post, put, patch
      reqContentType.includes('x-www-form-urlencoded')
      ? // FormData
        {
          data,
          transformRequest: [data => new URLSearchParams(data).toString()],
        }
      : // JSON
        {
          data,
        };

  // 发送请求
  try {
    showLoading && showLoadingBar();

    const res = await instance.request({
      ...reqBaseConf,
      ...reqPayload,
    });

    // 无响应
    if (!res) {
      showMessage &&
        showFlashMessage({
          type: flashMessageType.ERROR,
          message: '服务异常，请重试',
        });

      return null;
    }

    const { isOctetStream, code, message } = res;

    // 响应的是字节流
    if (isOctetStream) return res;

    // 响应的是 JSON
    // token 无效或过期
    if (code === bizCode.TOKEN_INVALID || code === bizCode.TOKEN_EXPIRES) {
      showTokenInvalidSystemNotice();
      await redirectLogin();

      return null;
    }

    // token 有效
    clearTokenInvalidSystemNotice();

    // 业务失败
    if (code !== bizCode.SUCCESS) {
      showMessage &&
        showFlashMessage({
          type: flashMessageType.ERROR,
          message,
        });

      // 如果不需要以默认的方式显示错误信息，那么将响应结果返回，便于自定义处理错误
      return showMessage ? null : res;
    }

    // 业务成功
    return res;
  } catch (e) {
    const errMsg = e.message.includes('timeout') ? '请求超时' : '网络异常';

    showFlashMessage({
      type: flashMessageType.ERROR,
      message: `${errMsg}，请重试`,
    });

    return null;
  } finally {
    showLoading && hideLoadingBar();
  }
};

// 挂载到 request 方法上，便于直接访问
request.requestMethods = requestMethods;
request.responseTypes = responseTypes;

export default request;
