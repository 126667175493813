<!-- layout-header -->
<template>
  <div class="layout-header">
    <div class="header-left">
      <!-- 操作 -->
      <div class="header-action-container">
        <header-action />
      </div>

      <!-- 面包屑 -->
      <div class="header-breadcrumb-container">
        <header-breadcrumb />
      </div>
    </div>

    <div class="header-right">
      <!-- 搜索 -->
      <div class="header-search-container">
        <header-search />
      </div>

      <!-- profile -->
      <div class="header-profile-container">
        <header-profile />
      </div>
    </div>
  </div>
</template>

<script>
  import HeaderAction from './header-action';
  import HeaderBreadcrumb from './header-breadcrumb';
  import HeaderSearch from './header-search';
  import HeaderProfile from './header-profile';

  export default {
    name: 'layout-header',
    components: {
      HeaderAction,
      HeaderBreadcrumb,
      HeaderSearch,
      HeaderProfile,
    },
  };
</script>

<style scoped lang="scss">
  .layout-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

    .header-left,
    .header-right {
      display: flex;
      flex-grow: 1;
      height: 100%;
    }

    .header-left {
      .header-action-container,
      .header-breadcrumb-container {
        display: flex;
        align-items: center;
        height: 100%;
      }

      .header-action-container {
        flex: 0 0 80px;
      }
    }

    .header-right {
      justify-content: flex-end;

      .header-search-container,
      .header-profile-container {
        display: flex;
        align-items: center;
        height: 100%;
      }

      .header-search-container {
        margin-right: 10px;
      }
    }
  }
</style>
