// 与角色权限无关的路由
export default [
  {
    name: 'login',
    path: '/login',
    alias: '',
    component: () =>
      import(/* webpackChunkName: 'login' */ '../../pages/login'),
    meta: {
      // 不使用布局组件 layout
      withoutLayout: true,
    },
  },
];
