import request, { requestMethods } from '../../../lib/request.js';

// 新增单个签约
export const addSign = async data => {
  const url = '/api/anchor/signs';

  return await request({
    url,
    method: requestMethods.POST,
    data,
  });
};

// 获取签约列表
export const getSigns = async data => {
  const url = '/api/anchor/signs';

  return await request({
    url,
    data,
  });
};

// 获取单个签约
export const getSign = async id => {
  const url = `/api/anchor/signs/${id}`;

  return await request({
    url,
  });
};

// 更新单个签约
export const updateSign = async data => {
  const { id } = data;
  const url = `/api/anchor/signs/${id}`;

  return await request({
    url,
    method: requestMethods.PUT,
    data,
  });
};

// 删除单个/多个签约
export const removeSigns = async ids => {
  const url = `/api/anchor/signs/${ids.join()}`;

  return await request({
    url,
    method: requestMethods.DELETE,
  });
};

// 新增单个签约备注
export const addSignRemark = async data => {
  const url = '/api/anchor/sign-remarks';

  return await request({
    url,
    method: requestMethods.POST,
    data,
  });
};

// 获取签约备注列表
export const getSignRemarks = async data => {
  const url = '/api/anchor/sign-remarks';

  return await request({
    url,
    data,
  });
};

// 获取单个签约备注
export const getSignRemark = async id => {
  const url = `/api/anchor/sign-remarks/${id}`;

  return await request({
    url,
  });
};

// 更新单个签约备注
export const updateSignRemark = async data => {
  const { id } = data;
  const url = `/api/anchor/sign-remarks/${id}`;

  return await request({
    url,
    method: requestMethods.PUT,
    data,
  });
};

// 删除单个签约备注
export const removeSignRemark = async id => {
  const url = `/api/anchor/sign-remarks/${id}`;

  return await request({
    url,
    method: requestMethods.DELETE,
  });
};
