import { getPromotions } from '../../../api/operation/promotion';

const state = {
  // 推广列表
  promotions: [],
  // 推广总数
  total: 0,
  // 当前操作的推广 id
  promotionId: '',
};

const mutations = {
  mttPromotions(state, payload) {
    state.promotions = payload || [];
  },
  mttTotal(state, payload) {
    state.total = payload || 0;
  },
  mttPromotionId(state, payload) {
    state.promotionId = payload || '';
  },
};

const actions = {
  // 获取推广列表
  async actPromotions({ commit }, payload) {
    const res = await getPromotions(payload);

    if (!res) return false;

    const { data: { total = 0, list = [] } = {} } = res;

    commit('mttPromotions', list);
    commit('mttTotal', total);

    return true;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
