import { getHistory, setHistory } from '../../../lib/library-anchor-history.js';
import {
  getAnchors,
  getAnchorCooperations,
  getAnchorFollowContacts,
} from '../../../api/library/anchor';

const state = {
  // 搜索历史
  searchHistories: getHistory(),
  // 查询参数
  searchParams: null,
  // 主播列表
  anchors: [],
  // 当前操作的主播 id
  operateAnchorId: '',
  // 当前操作的主播关联的合作历史列表
  operateAnchorCooperations: [],
  // 当前操作的主播关联的跟进联系记录列表
  operateAnchorFollowContacts: [],
  // 当前执行分配操作的主播对象
  assignAnchorObj: null,
};

const mutations = {
  mttSearchHistories(state, payload) {
    const histories = payload || [];

    state.searchHistories = histories;

    setHistory(histories);
  },
  mttSearchParams(state, payload) {
    state.searchParams = payload;
  },
  mttAnchors(state, payload) {
    state.anchors = payload || [];
  },
  mttOperateAnchorId(state, payload) {
    state.operateAnchorId = payload || '';
  },
  mttOperateAnchorCooperations(state, payload) {
    state.operateAnchorCooperations = payload || [];
  },
  mttOperateAnchorFollowContacts(state, payload) {
    state.operateAnchorFollowContacts = payload || [];
  },
  mttAssignAnchorObj(state, payload) {
    state.assignAnchorObj = payload || null;
  },
};

const actions = {
  async actAnchors({ state, commit }) {
    const res = await getAnchors(state.searchParams);

    if (!res) return false;

    const anchors = res.data || [];
    const firstAnchorId = anchors[0]?._id || '';

    commit('mttAnchors', anchors);
    commit('mttOperateAnchorId', firstAnchorId);

    return true;
  },
  async actAnchorCooperations({ state, commit }) {
    const { operateAnchorId } = state;
    const res = await getAnchorCooperations(operateAnchorId);

    if (!res) return false;

    commit('mttOperateAnchorCooperations', res.data);

    return true;
  },
  async actAnchorFollowContacts({ state, commit }) {
    const { operateAnchorId } = state;
    const res = await getAnchorFollowContacts(operateAnchorId);

    if (!res) return false;

    commit('mttOperateAnchorFollowContacts', res.data);

    return true;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
