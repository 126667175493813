<!-- header-profile -->
<template>
  <div class="header-profile">
    <!-- 消息 -->
    <div class="profile-notice" @click="handleProfileNoticeClick">
      <span
        class="notice-bell el-icon-message-solid"
        :class="{ unread: true }"
      />
    </div>

    <!-- 用户 -->
    <div class="profile-user">
      <el-dropdown @command="handleProfileUserItemClick">
        <div class="user-container">
          <el-image class="user-avatar" :src="userAvatar" fit="cover" />

          <span class="user-username">{{ username }}</span>
        </div>

        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="0">个人中心</el-dropdown-item>
          <el-dropdown-item :command="1">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <!-- 退出确认模态框 -->
    <confirm
      v-model="showLogoutConfirm"
      content="确定要退出登录吗?"
      content-icon-color="#E6A23C"
      @on-cancel="handleLogoutModalCancelClick"
      @on-ok="handleLogoutModalOkClick"
    />
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import Confirm from '../../../confirm';
  import avatarPlaceholder from '../../../../assets/img/user-placeholder.png';
  import { removeMenuHistory } from '../../../../lib/menu-history.js';
  import { redirectLogin } from '../../../../lib/login';

  export default {
    name: 'header-profile',
    components: {
      Confirm,
    },
    data() {
      return {
        noticeCenterRouteName: 'notice-center',
        showLogoutConfirm: false,
        showChangePwdModal: false,
      };
    },
    computed: {
      ...mapState(['userInfo']),

      // 用户头像url
      userAvatar() {
        const { avatar = '' } = this.userInfo || {};

        return avatar || avatarPlaceholder;
      },
      // 用户名
      username() {
        const { name } = this.userInfo || {};

        return name || '';
      },
    },
    methods: {
      // 消息按钮单击
      handleProfileNoticeClick() {
        if (this.$route.name === this.noticeCenterRouteName) return;

        this.$router.push({ name: this.noticeCenterRouteName });
      },
      // 用户下拉框选项单击
      handleProfileUserItemClick(command) {
        // 个人中心
        if (command === 0) {
          this.$router.push({ name: 'profile-account' });

          return;
        }

        // 退出登录
        if (command === 1) {
          this.showLogoutConfirm = true;

          return;
        }
      },
      // 退出登录模态框取消按钮单击
      handleLogoutModalCancelClick() {
        this.showLogoutConfirm = false;
      },
      // 退出登录模态框确认按钮单击
      handleLogoutModalOkClick() {
        removeMenuHistory();
        redirectLogin('', true);
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../../../style';

  .header-profile {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    .profile-notice,
    .profile-user {
      height: 100%;
      padding: 0 10px;
      cursor: pointer;

      &:hover {
        background-color: #f8f8f9;
      }
    }

    // 消息
    .profile-notice {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      .notice-bell {
        position: relative;
        font-size: 26px;
        color: #bcbcbc;
        opacity: 1;

        &::after {
          content: ' ';
          position: absolute;
          right: 0;
          bottom: 4px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: transparent;
        }

        &.unread {
          transform-origin: center;
          animation: notice-bell-animation 1.6s ease-in-out infinite
            alternate-reverse;

          @keyframes notice-bell-animation {
            0% {
              opacity: 0.3;
            }

            100% {
              opacity: 1;
            }
          }

          &::after {
            background-color: #f56c6c;
          }
        }
      }
    }

    // 用户
    .profile-user {
      padding-right: 20px;

      /deep/ .el-dropdown {
        height: 100%;
      }

      .user-container {
        display: flex;
        align-items: center;
        height: 100%;

        .user-avatar {
          width: 32px;
          height: 32px;
          margin-right: 10px;
          border-radius: 50%;
          overflow: hidden;
        }
      }
    }
  }
</style>
