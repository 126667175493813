import { getFollows, getFollowContacts } from '../../../api/anchor/follow';

const state = {
  // 跟进列表
  follows: [],
  // 跟进总数
  total: 0,
  // 当前操作的跟进 id
  followId: '',
  // 联系记录列表
  followContacts: [],
  // 当前操作的联系记录 id
  followContactId: '',
};

const getters = {
  // 当前操作的跟进对象
  followObj(state) {
    const { follows, followId } = state;

    return follows.find(item => item._id === followId) || null;
  },
  // 当前操作的跟进对象所关联的主播 id
  followAnchorId(state, getters) {
    return getters.followObj?.anchorId || '';
  },
  // 当前操作的跟进对象所关联的主播名称
  followAnchorName(state, getters) {
    return getters.followObj?.anchorName || '';
  },
  // 当前操作的跟进对象的状态
  followStatus(state, getters) {
    return getters.followObj?.status || '';
  },
  // 当前操作的跟进对象的商务
  followUserId(state, getters) {
    return getters.followObj?.userId || '';
  },
};

const mutations = {
  mttFollows(state, payload) {
    state.follows = payload || [];
  },
  mttTotal(state, payload) {
    state.total = payload || 0;
  },
  mttFollowId(state, payload) {
    state.followId = payload || '';
  },
  mttFollowContacts(state, payload) {
    state.followContacts = payload || [];
  },
  mttFollowContactId(state, payload) {
    state.followContactId = payload || '';
  },
};

const actions = {
  // 获取跟进列表
  async actFollows({ commit }, payload) {
    const res = await getFollows(payload);

    if (!res) return false;

    const { data: { total = 0, list = [] } = {} } = res;

    commit('mttFollows', list);
    commit('mttTotal', total);

    return true;
  },
  // 获取联系记录列表
  async actFollowContacts({ state, commit }) {
    const params = { followId: state.followId };
    const res = await getFollowContacts(params);

    if (!res) return false;

    const { data = [] } = res;

    commit('mttFollowContacts', data);

    return true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
