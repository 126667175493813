import request, { requestMethods } from '../../../lib/request.js';

// 新增单个跟进
export const addFollow = async data => {
  const url = '/api/anchor/follows';

  return await request({
    url,
    method: requestMethods.POST,
    data,
  });
};

// 获取跟进列表
export const getFollows = async data => {
  const url = '/api/anchor/follows';

  return await request({
    url,
    data,
  });
};

// 获取单个跟进
export const getFollow = async id => {
  const url = `/api/anchor/follows/${id}`;

  return await request({
    url,
  });
};

// 更新单个跟进
export const updateFollow = async data => {
  const { id } = data;
  const url = `/api/anchor/follows/${id}`;

  return await request({
    url,
    method: requestMethods.PUT,
    data,
  });
};

// 新增单个联系记录
export const addFollowContact = async data => {
  const url = '/api/anchor/follow-contacts';

  return await request({
    url,
    method: requestMethods.POST,
    data,
  });
};

// 获取联系记录列表
export const getFollowContacts = async data => {
  const url = '/api/anchor/follow-contacts';

  return await request({
    url,
    data,
  });
};

// 获取单个联系记录
export const getFollowContact = async id => {
  const url = `/api/anchor/follow-contacts/${id}`;

  return await request({
    url,
  });
};

// 更新单个联系记录
export const updateFollowContact = async data => {
  const { id } = data;
  const url = `/api/anchor/follow-contacts/${id}`;

  return await request({
    url,
    method: requestMethods.PUT,
    data,
  });
};

// 删除单个联系记录
export const removeFollowContact = async id => {
  const url = `/api/anchor/follow-contacts/${id}`;

  return await request({
    url,
    method: requestMethods.DELETE,
  });
};
