<!-- layout-tab -->
<template>
  <div class="layout-tab">
    <div class="tab-list">
      <el-tag
        class="tab-item"
        :class="{ active: menu.value === currRouteName }"
        v-for="(menu, index) in menuHistory"
        :key="menu._id"
        :closable="menu.value !== userHomeRouteName"
        effect="plain"
        disable-transitions
        @click="handleTabItemClick(menu)"
        @close="handleTabItemClose(menu, index)"
      >
        <span class="item-icon iconfont" :class="[`icon-${menu.value}`]" />
        <span>{{ menu.name }}</span>
      </el-tag>
    </div>

    <div class="tab-action">
      <el-dropdown @command="handleTabActionItemClick">
        <div class="action-container">
          <span class="el-icon-arrow-down" />
        </div>

        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="0">关闭左侧</el-dropdown-item>
          <el-dropdown-item :command="1">关闭右侧</el-dropdown-item>
          <el-dropdown-item :command="2">关闭其它</el-dropdown-item>
          <el-dropdown-item :command="3">全部关闭</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters, mapActions } from 'vuex';

  export default {
    name: 'layout-tab',
    computed: {
      ...mapState(['menuHistory']),
      ...mapGetters(['userHomeRouteName']),

      // 当前路由名称
      currRouteName() {
        const { name } = this.$route;

        return name;
      },
      // 当前路由名称对应的菜单项在历史记录中的索引
      currRouteMenuHistoryIndex() {
        return this.menuHistory.findIndex(
          item => item.value === this.currRouteName
        );
      },
    },
    methods: {
      ...mapActions(['actMenuHistory']),

      // tab 项单击
      handleTabItemClick(menu) {
        const { value = '' } = menu;

        if (value === this.currRouteName) return;

        this.$router.push({ name: value });
      },
      // tab 项关闭按钮单击
      handleTabItemClose(menu, index) {
        const clonedMenuHistory = [...this.menuHistory];
        const { value = '' } = menu;

        // 关闭的是自身
        if (value === this.currRouteName) {
          // 将要激活的菜单项索引
          const toActiveIndex =
            index === clonedMenuHistory.length - 1
              ? // 自身是最后一项，则激活前一项
                index - 1
              : // 自身非最后一项，则激活后一项
                index + 1;
          // 将要激活的路由名称
          const { value: toActiveRouteName = '' } =
            clonedMenuHistory[toActiveIndex] || {};

          toActiveRouteName && this.$router.push({ name: toActiveRouteName });
        }

        clonedMenuHistory.splice(index, 1);

        this.actMenuHistory(clonedMenuHistory);
      },
      // tab 操作下拉框选项单击
      handleTabActionItemClick(command) {
        const routeMenuHistoryIndex = this.currRouteMenuHistoryIndex;
        const menuHistory = this.menuHistory;
        let newMenuHistory = [];

        if (routeMenuHistoryIndex < 0) return;

        // 关闭左侧
        if (command === 0) {
          newMenuHistory = menuHistory.slice(routeMenuHistoryIndex);
        }
        // 关闭右侧
        else if (command === 1) {
          newMenuHistory = menuHistory.slice(0, routeMenuHistoryIndex + 1);
        }
        // 关闭其它
        else if (command === 2) {
          newMenuHistory = [menuHistory[routeMenuHistoryIndex]];
        }
        // 全部关闭
        else if (command === 3) {
          this.$router.push({ name: this.userHomeRouteName });
        }

        this.actMenuHistory(newMenuHistory);
      },
    },
  };
</script>

<style scoped lang="scss">
  .layout-tab {
    display: flex;
    align-items: center;
    height: 100%;

    .tab-list {
      flex-grow: 1;

      .tab-item {
        margin-left: 5px;
        font-size: 13px;
        cursor: pointer;

        .item-icon {
          margin-right: 4px;
          font-size: 13px;
        }

        &.el-tag--plain {
          border-color: #eee;
          color: #909399;

          .el-tag__close {
            color: #909399;

            &:hover {
              background-color: rgba(0, 0, 0, 0.1);
            }
          }
        }

        &.active {
          color: #409eff;
        }

        &:nth-of-type(1) {
          margin-left: 0;
        }
      }
    }

    .tab-action {
      flex: 0 0 32px;
      height: 32px;

      /deep/ .el-dropdown {
        width: 100%;
        height: 100%;
      }

      .action-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border: 1px solid #eee;
        border-radius: 3px;
        background-color: #fff;
        color: #909399;
        cursor: pointer;
      }
    }
  }
</style>
