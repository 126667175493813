import genMenuList from '../lib/gen-menu-list';
import genMenuTree from '../lib/gen-menu-tree';
import genButtonList from '../lib/gen-button-list';
import getFirstLeafMenu from '../lib/get-first-leaf-menu.js';
import roleDataPermission from '../enum/role-data-permission.js';

export default {
  // 资源列表，包括菜单和按钮
  resourceList(state) {
    const { resources } = state.userInfo || {};

    return resources || [];
  },
  // 菜单列表，一维数组
  menuList(state, getters) {
    return genMenuList(getters.resourceList);
  },
  // 菜单树，树形结构
  menuTree(state, getters) {
    return genMenuTree(getters.menuList);
  },
  // 按钮列表，一维数组
  buttonList(state, getters) {
    return genButtonList(getters.resourceList);
  },
  // 用户主页对应的菜单项
  userHomeMenu(state, getters) {
    return getFirstLeafMenu(getters.menuTree);
  },
  // 用户主页对应的路由名称
  userHomeRouteName(state, getters) {
    const { value = '' } = getters.userHomeMenu || {};

    return value || '';
  },
  // 当前登录用户的 id
  currentUserId(state) {
    return state.userInfo?._id || '';
  },
  // 当前用户是否拥有全部数据权限
  currentUserHasFullPermissions(state) {
    const { userInfo: { dataPermission } = {} } = state;

    return dataPermission === roleDataPermission.enum.all;
  },
};
