import Vue from 'vue';
import Vuex from 'vuex';
import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import home from './home';
import anchor from './anchor';
import library from './library';
import auth from './auth';
import sys from './sys';
import operation from './operation';

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    home,
    anchor,
    library,
    auth,
    sys,
    operation,
  },
});
