<!-- layout -->
<template>
  <div class="layout">
    <div class="layout-left" :style="layoutLeftStyle">
      <!-- logo -->
      <div class="layout-logo-container">
        <layout-logo />
      </div>

      <!-- 侧边栏 -->
      <nav class="layout-aside-container">
        <layout-aside />
      </nav>
    </div>

    <div class="layout-right">
      <!-- 头部 -->
      <header class="layout-header-container">
        <layout-header />
      </header>

      <!-- 标签页 -->
      <div class="layout-tab-container">
        <layout-tab />
      </div>

      <!-- 滚动容器 -->
      <el-scrollbar class="layout-scrollbar-container">
        <!-- 主体 -->
        <main class="layout-main-container">
          <slot />
        </main>

        <!-- 脚注 -->
        <footer class="layout-footer-container">
          <layout-footer />
        </footer>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import LayoutHeader from './layout-header';
  import LayoutLogo from './layout-logo';
  import LayoutAside from './layout-aside';
  import LayoutTab from './layout-tab';
  import LayoutFooter from './layout-footer';

  export default {
    name: 'layout',
    components: {
      LayoutHeader,
      LayoutLogo,
      LayoutAside,
      LayoutTab,
      LayoutFooter,
    },
    computed: {
      ...mapState(['isAsideCollapsed']),

      layoutLeftStyle() {
        return {
          flex: `0 0 ${this.isAsideCollapsed ? 64 : 246}px`,
        };
      },
    },
  };
</script>

<style scoped lang="scss">
  .layout {
    display: flex;
    width: 100vw;
    height: 100vh;

    .layout-left {
      z-index: 1;
      display: flex;
      flex-direction: column;
      height: 100%;
      background-color: #fff;
      box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
      transition: width 0.3s ease-in-out;

      .layout-logo-container {
        z-index: 1;
        flex: 0 0 64px;
        box-shadow: 0 1px 4px rgba(0, 21, 41, 0.04);
      }

      .layout-aside-container {
        display: flex;
        flex-grow: 1;
        overflow: hidden;
      }
    }

    .layout-right {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 100%;
      overflow: hidden;

      .layout-header-container {
        flex: 0 0 64px;
        background-color: #fff;
      }

      .layout-tab-container {
        flex: 0 0 44px;
        padding-left: 10px;
      }

      /deep/ .layout-scrollbar-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding-left: 10px;
        overflow: hidden;

        > .el-scrollbar__wrap {
          height: 100%;
          overflow: hidden auto;

          > .el-scrollbar__view {
            display: flex;
            flex-direction: column;
            min-height: 100%;
          }
        }

        .layout-main-container {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          padding: 15px;
          border-radius: 3px;
          background-color: #fff;
        }

        .layout-footer-container {
          flex: 0 0 30px;
        }
      }
    }
  }
</style>
