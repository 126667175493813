import { getMenuHistory } from '../lib/menu-history.js';
import { getLayoutConf } from '../lib/layout-conf.js';

export default {
  // 侧边栏是否折叠
  isAsideCollapsed: getLayoutConf()?.isAsideCollapsed || false,
  // 路由视图是否激活，用于自定义刷新当前页面
  isRouterViewActivated: true,
  // 菜单历史记录
  menuHistory: getMenuHistory(),
  // 用户信息
  userInfo: null,
};
