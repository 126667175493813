import { getSigns, getSignRemarks } from '../../../api/anchor/sign';

const state = {
  // 签约列表
  signs: [],
  // 签约总数
  total: 0,
  // 当前操作的签约 id
  signId: '',
  // 签约备注列表
  signRemarks: [],
  // 当前操作的签约备注 id
  signRemarkId: '',
};

const getters = {
  // 当前操作的签约对象
  signObj(state) {
    const { signs, signId } = state;

    return signs.find(item => item._id === signId) || null;
  },
  // 当前操作的签约对象所关联的主播名称
  signAnchorName(state, getters) {
    return getters.signObj?.anchorName || '';
  },
};

const mutations = {
  mttSigns(state, payload) {
    state.signs = payload || [];
  },
  mttTotal(state, payload) {
    state.total = payload || 0;
  },
  mttSignId(state, payload) {
    state.signId = payload || '';
  },
  mttSignRemarks(state, payload) {
    state.signRemarks = payload || [];
  },
  mttSignRemarkId(state, payload) {
    state.signRemarkId = payload || '';
  },
};

const actions = {
  // 获取签约列表
  async actSigns({ commit }, payload) {
    const res = await getSigns(payload);

    if (!res) return false;

    const { data: { total = 0, list = [] } = {} } = res;

    commit('mttSigns', list);
    commit('mttTotal', total);

    return true;
  },
  // 获取签约备注列表
  async actSignRemarks({ state, commit }) {
    const params = { signId: state.signId };
    const res = await getSignRemarks(params);

    if (!res) return false;

    const { data = [] } = res;

    commit('mttSignRemarks', data);

    return true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
