import { getSerials } from '../../../api/anchor/serial';

const state = {
  // 流水列表
  serials: [],
  // 流水总数
  total: 0,
  // 当前操作的流水 id
  serialId: '',
};

const getters = {
  // 当前操作的流水对象
  serialObj(state) {
    const { serials, serialId } = state;

    return serials.find(item => item._id === serialId) || null;
  },
  // 当前操作的流水对象所关联的主播名称
  serialAnchorName(state, getters) {
    return getters.serialObj?.anchorName || '';
  },
};

const mutations = {
  mttSerials(state, payload) {
    state.serials = payload || [];
  },
  mttTotal(state, payload) {
    state.total = payload || 0;
  },
  mttSerialId(state, payload) {
    state.serialId = payload || '';
  },
};

const actions = {
  // 获取流水列表
  async actSerials({ commit }, payload) {
    const res = await getSerials(payload);

    if (!res) return false;

    const { data: { total = 0, list = [] } = {} } = res;

    commit('mttSerials', list);
    commit('mttTotal', total);

    return true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
