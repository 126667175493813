import { layoutConfKey, isLayoutConfPermanent } from '../config/layout-conf';

const storage = isLayoutConfPermanent
  ? window.localStorage
  : window.sessionStorage;

// 获取布局配置
export const getLayoutConf = () => {
  const layoutConfStr = storage.getItem(layoutConfKey) || '';

  try {
    return JSON.parse(layoutConfStr);
  } catch {
    return null;
  }
};

// 设置布局配置
export const setLayoutConf = layoutConfArr => {
  storage.setItem(layoutConfKey, JSON.stringify(layoutConfArr || []));
};

// 清空布局配置
export const removeLayoutConf = () => storage.removeItem(layoutConfKey);
