import user from './user';
import role from './role';

export default {
  namespaced: true,
  modules: {
    user,
    role,
  },
};
