import { getServices } from '../../../api/operation/service';

const state = {
  // 客服列表
  services: [],
  // 客服总数
  total: 0,
  // 当前操作的客服 id
  serviceId: '',
};

const mutations = {
  mttServices(state, payload) {
    state.services = payload || [];
  },
  mttTotal(state, payload) {
    state.total = payload || 0;
  },
  mttServiceId(state, payload) {
    state.serviceId = payload || '';
  },
};

const actions = {
  // 获取客服列表
  async actServices({ commit }, payload) {
    const res = await getServices(payload);

    if (!res) return false;

    const { data: { total = 0, list = [] } = {} } = res;

    commit('mttServices', list);
    commit('mttTotal', total);

    return true;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
