import promotion from './promotion';
import service from './service';

export default {
  namespaced: true,
  modules: {
    promotion,
    service,
  },
};
