import resourceVisible from '../enum/resource-visible';

// 根据菜单列表和是否显示生成菜单树(树形结构)
const genMenuTree = (menuList, menuTree = []) => {
  if (!Array.isArray(menuList) || !menuList.length) return menuTree;

  const clonedMenuList = JSON.parse(JSON.stringify(menuList));

  clonedMenuList.forEach(menu => {
    const { visible, pid } = menu;

    // 不显示菜单项
    if (visible === resourceVisible.enum.invisible) return;

    if (!pid) {
      menuTree.push(menu);

      return;
    }

    const pMenu = clonedMenuList.find(menu => menu._id === pid);

    if (!pMenu) return;

    const { children = [] } = pMenu;

    pMenu.children = [...children, menu];
  });

  return menuTree;
};

export default genMenuTree;
