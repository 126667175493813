import { getUserInfo } from '../api/common';
import { setMenuHistory } from '../lib/menu-history';

export default {
  // 更新菜单历史记录
  actMenuHistory({ commit, getters }, payload) {
    const clonedPayload = [...payload];
    const userHomeMenu = getters.userHomeMenu;
    const userHomeMenuIndex = clonedPayload.findIndex(
      item => item.value === userHomeMenu.value
    );

    // 不包含用户主页对应的菜单项
    if (userHomeMenuIndex < 0) {
      clonedPayload.unshift(userHomeMenu);
    }
    // 用户主页对应的菜单项不是第一项
    else if (userHomeMenuIndex > 0) {
      clonedPayload.splice(userHomeMenuIndex, 1);
      clonedPayload.unshift(userHomeMenu);
    }

    commit('mttMenuHistory', clonedPayload);
    setMenuHistory(clonedPayload);

    return true;
  },
  // 获取用户信息
  async actUserInfo({ commit }) {
    const res = await getUserInfo();

    if (!res) return false;

    commit('mttUserInfo', res.data);

    return true;
  },
};
