import { getGroups, getUsers } from '../../../api/auth/user';

const state = {
  // 分组列表
  groups: [],
  // 当前选择的分组 id
  groupId: '',
  // 用户列表
  users: [],
  // 用户总数
  total: 0,
};

const mutations = {
  mttGroups(state, payload) {
    state.groups = payload || [];
  },
  mttGroupId(state, payload) {
    state.groupId = payload || '';
  },
  mttUsers(state, payload) {
    state.users = payload || [];
  },
  mttTotal(state, payload) {
    state.total = payload || 0;
  },
};

const actions = {
  // 获取分组列表
  async actGroups({ commit }, payload) {
    const res = await getGroups();

    if (!res) return false;

    commit('mttGroups', res.data);

    return true;
  },
  // 获取用户列表
  async actUsers({ commit }, payload) {
    const res = await getUsers(payload);

    if (!res) return false;

    const { data: { total = 0, list = [] } = {} } = res;

    commit('mttUsers', list);
    commit('mttTotal', total);

    return true;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
