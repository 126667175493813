import request, { requestMethods } from '../../../lib/request.js';

// 获取开播记录列表
export const getBroadcasts = async data => {
  const url = '/api/anchor/broadcasts';

  return await request({
    url,
    data,
  });
};

// 更新单个/多个开播记录的状态
export const updateBroadcastsStatus = async data => {
  const url = `/api/anchor/broadcasts`;

  return await request({
    url,
    method: requestMethods.PUT,
    data,
  });
};

// 新增单个开播记录备注
export const addBroadcastRemark = async data => {
  const url = '/api/anchor/broadcast-remarks';

  return await request({
    url,
    method: requestMethods.POST,
    data,
  });
};

// 获取开播记录备注列表
export const getBroadcastRemarks = async data => {
  const url = '/api/anchor/broadcast-remarks';

  return await request({
    url,
    data,
  });
};

// 获取单个开播记录备注
export const getBroadcastRemark = async id => {
  const url = `/api/anchor/broadcast-remarks/${id}`;

  return await request({
    url,
  });
};

// 更新单个开播记录备注
export const updateBroadcastRemark = async data => {
  const { id } = data;
  const url = `/api/anchor/broadcast-remarks/${id}`;

  return await request({
    url,
    method: requestMethods.PUT,
    data,
  });
};

// 删除单个开播记录备注
export const removeBroadcastRemark = async id => {
  const url = `/api/anchor/broadcast-remarks/${id}`;

  return await request({
    url,
    method: requestMethods.DELETE,
  });
};
