<!-- header-search -->
<template>
  <div class="header-search" :class="{ active: isActivated }">
    <div class="search-input">
      <el-autocomplete
        ref="input"
        v-model="keyword"
        :placeholder="inputPlaceholder"
        size="small"
        value-key="fullName"
        highlight-first-item
        :fetch-suggestions="getMatchedMenus"
        @focus="isActivated = true"
        @blur="isActivated = false"
        @select="handleSearchInputItemSelect"
      />
    </div>

    <div class="search-icon" @click="handleSearchIconClick">
      <span class="icon-entity el-icon-search" />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { isMac } from '../../../../util/browser';

  // 递归处理菜单树
  const recursive = (menuTree, parentMenuFullName = '', menuList = []) => {
    if (!Array.isArray(menuTree) || !menuTree.length) return menuList;

    menuTree.forEach(menu => {
      const { disable, children, name } = menu;

      if (disable) return;

      const fullName = parentMenuFullName
        ? `${parentMenuFullName}/${name}`
        : name;

      // 保存资源项的副本
      menuList.push({
        ...menu,
        fullName,
      });

      recursive(children, fullName, menuList);
    });

    return menuList;
  };

  export default {
    name: 'header-search',
    data() {
      return {
        // 搜索框组件中的 input 元素
        inputEl: null,
        // 搜索框是否激活
        isActivated: false,
        // 搜索框文本内容
        keyword: '',
      };
    },
    computed: {
      ...mapGetters(['menuTree']),

      // 搜索框 placeholder
      inputPlaceholder() {
        return this.isActivated
          ? '请输入菜单名称'
          : `${isMac() ? '⌘' : 'Ctrl'} + F 激活搜索`;
      },
      // 匹配到的菜单列表
      matchedMenuList() {
        const keyword = this.keyword;

        if (!keyword) return [];

        const menusList = recursive(this.menuTree).filter(
          item => !item.children || !item.children.length
        );

        return menusList.filter(item => item.fullName.includes(this.keyword));
      },
    },
    created() {
      this.registerWindowEvent();
    },
    methods: {
      // 注册全局搜索事件，ctrl/command + f
      registerWindowEvent() {
        window.addEventListener('keydown', this.handleKeyboardKeydown);

        // 移除全局搜索事件
        this.$once('hook:beforeDestroy', () =>
          window.removeEventListener('keydown', this.handleKeyboardKeydown)
        );
      },
      // 获取搜索框 input 元素
      getSearchInputEl() {
        const { $el: inputComEl } = this.$refs.input;
        const inputEl = inputComEl.querySelector('input');

        this.inputEl = inputEl;

        return inputEl;
      },
      // 聚焦搜索框
      focusSearchInput() {
        const inputEl = this.inputEl || this.getSearchInputEl();

        inputEl.focus();
      },
      // 失焦搜索框
      blurSearchInput() {
        const inputEl = this.inputEl || this.getSearchInputEl();

        inputEl.blur();
      },
      // 激活搜索框
      activeSearchInput() {
        this.keyword = '';

        this.focusSearchInput();
      },
      // 失活搜索框
      inactiveSearchInput() {
        this.keyword = '';

        this.blurSearchInput();
      },
      // 获取匹配到的菜单列表
      getMatchedMenus(keyword, callback) {
        callback(this.matchedMenuList);
      },
      // 键盘按键按下
      handleKeyboardKeydown(e) {
        const { code, keyCode, ctrlKey, metaKey } = e;

        // 未按下 ctrl 或 meta(command)
        if (!(ctrlKey || metaKey)) return;

        // 非 F 键
        if (code !== 'KeyF' || keyCode !== 70) return;

        e.preventDefault();
        this.activeSearchInput();
      },
      // 搜索图标单击
      handleSearchIconClick() {
        this.activeSearchInput();
      },
      // 搜索框下拉选项选中
      handleSearchInputItemSelect(item) {
        const { name: currRouteName } = this.$route;
        const { value = '' } = item;

        if (!value || value === currRouteName) return;

        this.inactiveSearchInput();
        this.$router.push({ name: value });
      },
    },
  };
</script>

<style scoped lang="scss">
  .header-search {
    position: relative;
    min-width: 32px;
    z-index: 998;

    // 搜索框
    .search-input {
      width: 300px;
      transition: all 0.2s ease-in-out;

      /deep/.el-autocomplete {
        width: 100%;

        .el-input__inner {
          padding-right: 55px;
          border-color: #eee;
          border-radius: 16px;
        }

        .el-input__suffix {
          right: 30px;
        }
      }
    }

    // 搜索图标
    .search-icon {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.05);
      color: #999;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      .icon-entity {
        font-size: 18px;
      }
    }

    &.active {
      .search-input /deep/ .el-autocomplete .el-input__inner {
        border-color: #409eff;
      }

      // 搜索图标
      .search-icon {
        background-color: rgba(64, 158, 255, 1);
        color: #fff;
      }
    }
  }
</style>
