import Vue from 'vue';
import VueRouter from 'vue-router';
import publicRoutes from './public';
import protectedRoutes from './protected';
import store from '../store';
import { getToken } from '../lib/token';
import { redirectLogin, showLoginSystemNotice } from '../lib/login';
import getAppBootstrapData from '../lib/get-app-bootstrap-data';
import routerAuth from '../lib/router-auth';
import { showSystemNotice, systemNoticeType } from '../lib/system-notice';

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;

// 重写 vue-router 的 push 方法，在内部消化 'NavigationDuplicated' 的错误
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  return onResolve || onReject
    ? originalPush.call(this, location, onResolve, onReject)
    : originalPush.call(this, location).catch(err => err);
};

// 路由器
const router = new VueRouter({
  mode: 'hash',
  routes: [...publicRoutes, ...protectedRoutes],
});

// 路由鉴权
router.beforeEach(async (to, from, next) => {
  const { name, fullPath } = to;

  // 登录页
  if (name === 'login') {
    next();

    return;
  }

  // 未登录
  if (!getToken()) {
    showLoginSystemNotice();

    await redirectLogin(fullPath);

    return;
  }

  // 登录了，但是未通过登录操作获取应用启动数据
  store.state.userInfo || (await getAppBootstrapData());

  // 路由鉴权
  if (!routerAuth(name)) {
    showSystemNotice({
      type: systemNoticeType.ERROR,
      title: '暂无权限',
      message: '暂无访问权限，请联系管理员!',
    });

    return;
  }

  next();
});

export default router;
