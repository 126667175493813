<template>
  <div class="layout-footer">
    <p class="footer-desc">
      <span>Copyright&copy;2022</span>
      <span>YiJin</span>
      <span>All Rights Reserved.</span>
    </p>
  </div>
</template>

<script>
  export default {
    name: 'layout-footer',
  };
</script>

<style scoped lang="scss">
  .layout-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .footer-desc {
      margin: 0;
      color: #999;

      > span {
        margin-left: 10px;
      }
    }
  }
</style>
