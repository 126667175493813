import request from '../../lib/request.js';

// 获取指定时间范围内排期的游戏列表、主播列表
export const getGamesAndAnchorsByDateRange = async data => {
  const url = '/api/home/common/games-anchors';

  return await request({
    url,
    data,
  });
};

// 获取概览中效益统计
export const getOverviewBenefit = async data => {
  const url = '/api/home/overview/benefit';

  return await request({
    url,
    data,
  });
};

// 获取概览中工作统计
export const getOverviewWork = async data => {
  const url = '/api/home/overview/work';

  return await request({
    url,
    data,
  });
};

// 获取游戏中占比统计
export const getGamePercent = async data => {
  const url = '/api/home/game/percent';

  return await request({
    url,
    data,
  });
};

// 获取游戏中排行统计
export const getGameRank = async data => {
  const url = '/api/home/game/rank';

  return await request({
    url,
    data,
  });
};

// 获取游戏中工作统计
export const getGameBenefit = async data => {
  const url = '/api/home/game/benefit';

  return await request({
    url,
    data,
  });
};

// 获取主播中效益统计
export const getAnchorBenefit = async data => {
  const url = '/api/home/anchor/benefit';

  return await request({
    url,
    data,
  });
};

// 获取主播中占比统计
export const getAnchorPercent = async data => {
  const url = '/api/home/anchor/percent';

  return await request({
    url,
    data,
  });
};

// 获取主播中排行统计
export const getAnchorRank = async data => {
  const url = '/api/home/anchor/rank';

  return await request({
    url,
    data,
  });
};

// 获取商务中占比统计
export const getUserPercent = async data => {
  const url = '/api/home/user/percent';

  return await request({
    url,
    data,
  });
};

// 获取商务中排行统计
export const getUserRank = async data => {
  const url = '/api/home/user/rank';

  return await request({
    url,
    data,
  });
};

// 获取商务中统计数据
export const getUserStatistics = async data => {
  const url = '/api/home/user/statistics';

  return await request({
    url,
    data,
  });
};
