import request, { requestMethods } from '../../../lib/request.js';

// 新增单个推广
export const addPromotion = async data => {
  const url = '/api/operation/promotions';

  return await request({
    url,
    method: requestMethods.POST,
    data,
  });
};

// 获取推广列表
export const getPromotions = async data => {
  const url = '/api/operation/promotions';

  return await request({
    url,
    data,
  });
};

// 获取单个推广
export const getPromotion = async id => {
  const url = `/api/operation/promotions/${id}`;

  return await request({
    url,
  });
};

// 更新单个推广
export const updatePromotion = async data => {
  const { id } = data;
  const url = `/api/operation/promotions/${id}`;

  return await request({
    url,
    method: requestMethods.PUT,
    data,
  });
};

// 删除单个推广
export const removePromotion = async id => {
  const url = `/api/operation/promotions/${id}`;

  return await request({
    url,
    method: requestMethods.DELETE,
  });
};

// 更新单个推广的状态
export const updatePromotionStatus = async data => {
  const url = '/api/operation/promotions/update/status';

  return await request({
    url,
    method: requestMethods.PUT,
    data,
  });
};
