// 角色数据权限
export default {
  map: {
    1: '全部',
    2: '分组',
    3: '个人',
  },
  enum: {
    all: 1,
    group: 2,
    self: 3,
  },
};
