import { getLayoutConf, setLayoutConf } from '../lib/layout-conf.js';

export default {
  // 更新侧边栏折叠状态
  mttIsAsideCollapse(state, payload) {
    const isAsideCollapsed = !!payload;
    const layoutConf = getLayoutConf() || {};

    state.isAsideCollapsed = isAsideCollapsed;
    layoutConf.isAsideCollapsed = isAsideCollapsed;

    setLayoutConf(layoutConf);
  },
  // 更新路由视图组件激活状态
  mttIsRouterViewActivated(state, payload) {
    state.isRouterViewActivated = !!payload;
  },
  // 更新菜单历史记录
  mttMenuHistory(state, payload) {
    state.menuHistory = payload || [];
  },
  // 更新用户信息
  mttUserInfo(state, payload) {
    state.userInfo = payload;
  },
};
