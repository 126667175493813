<template>
  <el-dialog
    class="confirm"
    :title="title"
    :width="`${width}px`"
    :visible.sync="open"
    :append-to-body="appendToBody"
    :modal="showModal"
    :show-close="showCloseButton"
    :close-on-click-modal="closeOnClickModal"
    :close-on-press-escape="closeOnPressEscape"
    @close="handleDialogClose"
  >
    <div class="confirm-content">
      <slot v-if="hasDefaultSlot" />

      <div v-else class="content-container">
        <span
          class="content-icon"
          :class="contentIconName"
          :style="{ color: contentIconColor }"
        />
        <p class="content-entity">{{ content }}</p>
      </div>
    </div>

    <!-- 底部按钮 -->
    <div slot="footer">
      <el-button
        v-if="showCancelButton"
        :type="cancelButtonType"
        :size="buttonSize"
        @click="handleCancelClick"
      >
        {{ cancelButtonText }}
      </el-button>

      <el-button
        v-if="showOkButton"
        :loading="okButtonLoading"
        :type="okButtonType"
        :size="buttonSize"
        @click="handleOkClick"
      >
        {{ okButtonText }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    name: 'confirm',
    props: {
      // 是否开启对话框
      value: {
        type: Boolean,
        default: false,
      },
      // 对话框标题
      title: {
        type: String,
        default: '确认',
      },
      // 对话框内容
      content: {
        type: String,
        default: '确定要删除吗?',
      },
      // 对话框内容图标类名
      contentIconName: {
        type: String,
        default: 'el-icon-warning',
      },
      // 对话框内容图标颜色
      contentIconColor: {
        type: String,
        default: '#F56C6C',
      },
      // 对话框宽度，px
      width: {
        type: Number,
        default: 400,
      },
      // 是否将自身插入至 body 元素中
      appendToBody: {
        type: Boolean,
        default: true,
      },
      // 是否显示遮罩层
      showModal: {
        type: Boolean,
        default: true,
      },
      // 是否显示关闭按钮
      showCloseButton: {
        type: Boolean,
        default: true,
      },
      // 是否显示取消按钮
      showCancelButton: {
        type: Boolean,
        default: true,
      },
      // 是否显示确定按钮
      showOkButton: {
        type: Boolean,
        default: true,
      },
      // 取消按钮的文本内容
      cancelButtonText: {
        type: String,
        default: '取消',
      },
      // 确定按钮的文本内容
      okButtonText: {
        type: String,
        default: '确定',
      },
      // 取消按钮的类型
      cancelButtonType: {
        type: String,
        default: 'default',
      },
      // 确定按钮的类型
      okButtonType: {
        type: String,
        default: 'primary',
      },
      // 按钮的尺寸
      buttonSize: {
        type: String,
        validator: val => ['default', 'medium', 'small', 'mini'].includes(val),
        default: 'small',
      },
      // 是否可通过点击遮罩关闭
      closeOnClickModal: {
        type: Boolean,
        default: true,
      },
      // 是否可通过按下 ESC 键关闭
      closeOnPressEscape: {
        type: Boolean,
        default: true,
      },
      // 确定按钮加载中
      okButtonLoading: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      // 是否开启对话框
      open: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      // 显示默认文本还是slot内容
      hasDefaultSlot() {
        return !!this.$slots.default;
      },
    },
    methods: {
      // 取消按钮单击
      handleCancelClick() {
        this.open = false;
      },
      // 确定按钮单击
      handleOkClick() {
        this.$emit('on-ok');
      },
      // 对话框关闭
      handleDialogClose() {
        this.$emit('on-close');
      },
    },
  };
</script>

<style scoped lang="scss">
  .confirm {
    /deep/ .el-dialog__header {
      padding: 15px 15px 10px;

      .el-dialog__headerbtn {
        right: 15px;
      }
    }

    /deep/ .el-dialog__body {
      padding: 10px 15px;
    }

    /deep/ .el-dialog__footer {
      padding: 5px 15px 20px;
    }

    .content-container {
      display: flex;
      align-items: flex-start;

      .content-icon {
        margin-right: 5px;
        font-size: 18px;
      }

      .content-entity {
        flex-grow: 1;
        margin: 0;
      }
    }
  }
</style>
