// 系统管理
export default [
  {
    name: 'sys-dictionary',
    path: '/sys-dictionary',
    component: () =>
      import(
        /* webpackChunkName: 'sys-dictionary' */ '../../pages/sys/sys-dictionary'
      ),
  },
  {
    name: 'sys-resource',
    path: '/sys-resource',
    component: () =>
      import(
        /* webpackChunkName: 'sys-resource' */ '../../pages/sys/sys-resource'
      ),
  },
  {
    name: 'sys-operation',
    path: '/sys-operation',
    component: () =>
      import(
        /* webpackChunkName: 'sys-operation' */ '../../pages/sys/sys-operation'
      ),
  },
];
