import { getRoles } from '../../../api/auth/role';

const state = {
  // 角色列表
  roles: [],
  // 角色总数
  total: 0,
};

const mutations = {
  mttRoles(state, payload) {
    state.roles = payload || [];
  },
  mttTotal(state, payload) {
    state.total = payload || 0;
  },
};

const actions = {
  // 获取角色列表
  async actRoles({ commit }, payload) {
    const res = await getRoles(payload);

    if (!res) return false;

    const { data: { total = 0, list = [] } = {} } = res;

    commit('mttRoles', list);
    commit('mttTotal', total);

    return true;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
