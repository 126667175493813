import { render, staticRenderFns } from "./header-search.vue?vue&type=template&id=52793be0&scoped=true&"
import script from "./header-search.vue?vue&type=script&lang=js&"
export * from "./header-search.vue?vue&type=script&lang=js&"
import style0 from "./header-search.vue?vue&type=style&index=0&id=52793be0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52793be0",
  null
  
)

export default component.exports