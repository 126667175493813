import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

// loading bar 是否已开启
let isLoadingBarStarted = false;
// 当前尚未完成的请求数
let pendingRequestCount = 0;

// 显示 loading bar
export const showLoadingBar = (forHttpRequest = true) => {
  // 如果不是用于 http 请求，则直接开启，无需考虑当前尚未完成的请求数
  if (!forHttpRequest) {
    isLoadingBarStarted || NProgress.start();

    return;
  }

  pendingRequestCount++;

  if (isLoadingBarStarted) return;

  isLoadingBarStarted = true;

  NProgress.start();
};

// 隐藏 loading bar
export const hideLoadingBar = (forHttpRequest = true) => {
  // 如果不是用于 http 请求，则直接结束，无需考虑当前尚未完成的请求数
  if (!forHttpRequest) {
    isLoadingBarStarted && NProgress.done();

    return;
  }

  pendingRequestCount--;

  if (pendingRequestCount > 0) return;

  pendingRequestCount = 0;
  isLoadingBarStarted = false;

  NProgress.done();
};
