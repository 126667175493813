// 资料库
export default [
  {
    name: 'library-anchor',
    path: '/library-anchor',
    component: () =>
      import(
        /* webpackChunkName: 'library-anchor' */ '../../pages/library/library-anchor'
      ),
    meta: {
      keepAlive: true,
    },
  },
];
