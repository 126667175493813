import request, { requestMethods } from '../../../lib/request.js';

// 获取主播列表
export const getAnchors = async data => {
  const url = '/api/library/anchors';

  return await request({
    url,
    data,
  });
};

// 获取主播的合作列表
export const getAnchorCooperations = async id => {
  const url = `/api/library/anchors/${id}/cooperations`;

  return await request({ url });
};

// 获取主播的跟进联系记录列表
export const getAnchorFollowContacts = async id => {
  const url = `/api/library/anchors/${id}/follow-contacts`;

  return await request({ url });
};

// 更新主播的归属绑定，归属个人还是分组
export const updateAnchorBinding = async data => {
  const { id } = data;
  const url = `/api/library/anchors/${id}/belongs-binding`;

  return await request({
    url,
    method: requestMethods.PUT,
    data,
  });
};
