import { Message } from 'element-ui';

// flash message type
export const flashMessageType = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};

// 显示 flash message
export const showFlashMessage = options => Message(options);

// 清空 flash message
export const clearFlashMessage = message => Message.closeAll();
